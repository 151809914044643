import BaseController from "@/api/shared/baseController";
import AbortService from "@/services/abortService";
import urls, { urlTemplateParts } from "@/api/config/urls";
import { ApiCalendarYear } from "@/api/calendar/types/apiCalendarYear";

export class CalendarController extends BaseController {
	constructor(abortService: AbortService) {
		super(abortService);
	}
	
	getWorkingDaysAndHolidays = async (year: number | string) => {
		return await this.client.get<ApiCalendarYear>(urls.calendar.calendar.getWorkingDaysAndHolidays.replace(urlTemplateParts.id, String(year)));
	};
}
