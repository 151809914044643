import { eachDayOfInterval, isSameDay } from "date-fns";
import { HrNewVacation } from "@/types/hr/vacation/hrNewVacation";
import { CalendarDate } from "@/types/calendar/calendarDate";
import { ApiCalendarDayTypeEnum } from "@/api/calendar/types/ApiCalendarDayTypeEnum";

export const calculateVacationDays = (vacation: HrNewVacation, calendarDates: CalendarDate[]): number => {
	const preparedHolidays = calendarDates.filter(x => x.type === ApiCalendarDayTypeEnum.Holiday).map(x => x.date);
	const total = eachDayOfInterval({ start: new Date(vacation.startDate!), end: new Date(vacation.endDate!) });
	
	return total.filter(date => !preparedHolidays.some(holiday => isSameDay(date, holiday))).length;
};

export const getVacationsWithDays = (vacations: HrNewVacation[], calendarDates: CalendarDate[]): HrNewVacation[] => {
	return vacations.map(vacation => ({
		...vacation,
		days: calculateVacationDays(vacation, calendarDates)
	}));
};
