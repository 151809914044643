import { actionTypes, getterTypes, mutationTypes, namespace } from "@/store/hr/modules/employee/types";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import router from "@/router/hr";
import { HrController } from "@/api/hr";
import EmployeeState from "@/store/hr/modules/employee/types/employeeState";
import employeeStandardAwarenessModule from "@/store/hr/modules/employee/modules/standardAwareness";
import employeeDisciplineModule from "@/store/hr/modules/employee/modules/discipline";
import employeeKpiModule from "@/store/hr/modules/employee/modules/kpi";
import employeeFeedbackModule from "@/store/hr/modules/employee/modules/feedback";
import employeeSubordinatesModule from "@/store/hr/modules/employee/modules/subordinates";
import employeeColleaguesModule from "@/store/hr/modules/employee/modules/colleagues";
import employeeExpectationsModule from "@/store/hr/modules/employee/modules/expectations";
import employeePromisesModule from "@/store/hr/modules/employee/modules/promises";
import employeeProfileModule from "@/store/hr/modules/employee/modules/profile";
import { resolveNestedState } from "@/utils/vuexModules";
import UserState from "@/store/hr/modules/user/types/userState";
import storeManager from "@/store/manager";
import { cloneDeep, isNil } from "lodash";
import { ApiHrEmployee } from "@/api/hr/types/apiHrEmployee";
import HttpNotFoundException from "@/exceptions/httpNotFoundException";
import { HrVacationApplicationController } from "@/api/hr/vacationApplication";
import { closestIndexTo, differenceInCalendarDays, getYear, isSameDay, isWeekend, subDays } from "date-fns";
import {
	HR_VACATION_APPLICATION_REQUIRES_ALERT_DEADLINE_MAX_DAYS,
	HR_VACATION_APPLICATION_REQUIRES_ALERT_MAX_DAYS,
	HR_VACATION_APPLICATION_REQUIRES_ALERT_MIN_DAYS
} from "@/constants/hr/date";
import { HrClosestVacationService } from "@/types/hr/vacation/hrClosestVacation";
import { CalendarController } from "@/api/calendar";
import { ApiCalendarDayTypeEnum } from "@/api/calendar/types/ApiCalendarDayTypeEnum";
import { CalendarDateHelper } from "@/types/calendar/calendarDate";

const abortService = new AbortService();
const hrController = new HrController(abortService);
const hrVacationApplicationController = new HrVacationApplicationController(abortService);
const calendarController = new CalendarController(abortService);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new EmployeeState(
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmployeeState, any>>{
	[getterTypes.currentUser]: (state, getters, rootState) => {
		return resolveNestedState<UserState>(rootState, storeManager.hr.user.namespace).user;
	}
};

const actions = <ActionTree<EmployeeState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, getters }) {
		await dispatch(actionTypes.initializeBase);
		
		const currentUser = getters[getterTypes.currentUser] as ApiHrEmployee;
		const tasks = [dispatch(actionTypes.fetchPermissions)];
		
		if(router.currentRoute.params.id === currentUser.id)
			tasks.push(dispatch(actionTypes.checkClosestVacations));
		
		await Promise.all(tasks);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
	},
	async [actionTypes.checkClosestVacations]({ commit, getters, dispatch }) {
		try {
			const currentUser = getters[getterTypes.currentUser] as ApiHrEmployee;
			
			const items = await hrVacationApplicationController.getAvailableVacations(currentUser.id);
			
			const vacations = items.map(x => HrClosestVacationService.map(x));
			
			const filteredVacations = vacations.filter(x => {
				const diff = differenceInCalendarDays(x.startDate, new Date());
				return !(diff < HR_VACATION_APPLICATION_REQUIRES_ALERT_MIN_DAYS || diff > HR_VACATION_APPLICATION_REQUIRES_ALERT_MAX_DAYS);
			});
			
			const closestIdx = closestIndexTo(new Date(), filteredVacations.map(x => x.startDate));
			
			if(isNil(closestIdx))
				return;
			
			const closest = filteredVacations[closestIdx];
			
			const { dates } = await calendarController.getWorkingDaysAndHolidays(getYear(closest.startDate));
			const mappedDates = dates.map(x => CalendarDateHelper.map(x));
			
			const holidays = mappedDates.filter(x => [ApiCalendarDayTypeEnum.Holiday, ApiCalendarDayTypeEnum.Weekend].includes(x.type));
			const workingDays = mappedDates.filter(x => x.type === ApiCalendarDayTypeEnum.WorkingDay);
			let deadline = subDays(closest.startDate, HR_VACATION_APPLICATION_REQUIRES_ALERT_DEADLINE_MAX_DAYS);
			let isDeadlineCorrect = false;
			
			do {
				if((isWeekend(deadline) && !workingDays.some(x => isSameDay(x.date, deadline))) ||
					holidays?.some(x => isSameDay(x.date, deadline)))
					deadline = subDays(deadline, 1);
				else
					isDeadlineCorrect = true;
			} while (!isDeadlineCorrect);
			
			commit(mutationTypes.SET_CLOSEST_VACATION, { ...closest, deadline });
		} catch (error) {
			if(error.constructor === HttpNotFoundException)
				return;
			
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		}
	},
	async [actionTypes.fetchDepartments]({ commit, state, dispatch }) {
		if(state.departments.length > 0)
			return;
		
		commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, true);
		
		try {
			const items = await hrController.getDepartments();
			
			commit(mutationTypes.SET_DEPARTMENTS, items);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_DEPARTMENTS_LOADING, false);
		}
	},
	async [actionTypes.fetchPermissions]({ commit, dispatch }) {
		commit(mutationTypes.SET_IS_PERMISSIONS_LOADING, true);
		
		try {
			const permissions = await hrController.getEmployeePermissions(router.currentRoute.params.id);
			
			commit(mutationTypes.SET_PERMISSIONS, permissions);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_PERMISSIONS_LOADING, false);
		}
	}
};

const mutations = <MutationTree<EmployeeState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	[mutationTypes.SET_IS_DEPARTMENTS_LOADING](state, value) {
		state.isDepartmentsLoading = value;
	},
	[mutationTypes.SET_DEPARTMENTS](state, value) {
		state.departments = value;
	},
	[mutationTypes.SET_IS_PERMISSIONS_LOADING](state, value) {
		state.isPermissionsLoading = value;
	},
	[mutationTypes.SET_PERMISSIONS](state, value) {
		state.permissions = value;
	},
	[mutationTypes.SET_CLOSEST_VACATION](state, value) {
		state.closestVacation = cloneDeep(value);
	}
};

const modules = {
	[employeeStandardAwarenessModule.namespace]: {
		...employeeStandardAwarenessModule
	},
	[employeeDisciplineModule.namespace]: {
		...employeeDisciplineModule
	},
	[employeeKpiModule.namespace]: {
		...employeeKpiModule
	},
	[employeeFeedbackModule.namespace]: {
		...employeeFeedbackModule
	},
	[employeeColleaguesModule.namespace]: {
		...employeeColleaguesModule
	},
	[employeeSubordinatesModule.namespace]: {
		...employeeSubordinatesModule
	},
	[employeeExpectationsModule.namespace]: {
		...employeeExpectationsModule
	},
	[employeePromisesModule.namespace]: {
		...employeePromisesModule
	},
	[employeeProfileModule.namespace]: {
		...employeeProfileModule
	}
};

export {
	namespace, state, getters, actions, mutations, modules
};

const employeeModule = {
	namespace, state, getters, actions, mutations, modules, namespaced: true
};

export default employeeModule;
