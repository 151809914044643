import IPageState from "@/store/shared/base/types/pageState";
import { ApiHrDepartmentsItem } from "@/api/hr/types/apiHrDepartmentsItem";
import { HrClosestVacation } from "@/types/hr/vacation/hrClosestVacation";

export default class EmployeeState implements IPageState {
	constructor(
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isDepartmentsLoading: boolean = false,
		public permissions: string[] = [],
		public isPermissionsLoading: boolean = false,
		public departments: ApiHrDepartmentsItem[] = [],
		public closestVacation: HrClosestVacation = {} as HrClosestVacation,
	)
	{
	}
}
