<template>
	<hr-content-layout container-class="pt-1 pt-lg-2 px-4 px-lg-4" bg-height="112">
		<v-card elevation="0" class="kpi-card px-6 py-6 mb-6">
			<div class="d-flex flex-wrap" style="gap: 16px">
				<bod-autocomplete class="kpi-field"
								  hide-details
								  item-value="value"
								  item-text="text"
								  style="width: 290px"
								  :items="vacationTypes"
								  multiple
								  v-model="internalFilterValues.vacationTypes"
								  color="blue"
								  :placeholder="$t('fields.vacationTypes.placeholder')">
				</bod-autocomplete>

				<bod-autocomplete class="kpi-field"
								  hide-details
								  item-value="value"
								  item-text="text"
								  style="width: 240px"
								  :items="applicationStates"
								  multiple
								  v-model="internalFilterValues.applicationStates"
								  color="blue"
								  :placeholder="$t('fields.status.placeholder')">
				</bod-autocomplete>
				
				<bod-autocomplete item-text="fullName"
								  item-value="id"
								  class="kpi-field"
								  v-if="isAdmin"
								  multiple
								  :loading="isEmployeesLoading"
								  style="width: 350px"
								  :items="employees"
								  v-model="internalFilterValues.employeeIds"
								  color="blue"
								  :placeholder="$t('fields.hrFilterEmployee.placeholder')"
								  hide-details>
				</bod-autocomplete>
				
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty"
						 color="primary" outlined elevation="0">
					{{ $t("buttons.reset") }}
				</frp-btn>
				
				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged"
						 no-margin color="blue" dark elevation="0">
					{{ $t("buttons.apply") }}
				</frp-btn>
				
				<v-spacer></v-spacer>
				
				<frp-btn :href="externalUrls.manuals.hrVacationApplications"
						 target="_blank"
						 color="primary"
						 elevation="0"
						 outlined>
					{{ $t("buttons.manual") }}
				</frp-btn>
				
				<frp-btn no-margin color="blue" dark elevation="0" :to="{ name: RouteNames.VACATION_APPLICATION_CREATE }">
					{{ $t("buttons.createVacationApplication") }}
				</frp-btn>
			</div>
		</v-card>
		
		<v-card elevation="0" class="kpi-card pa-0">
			<v-row>
				<v-col class="py-0">
					<v-data-table :headers="headers"
								  :loading-text="$t('tables.loading')"
								  :loading="isItemsLoading"
								  hide-default-footer
								  item-key="id"
								  :items="formattedItems"
								  :mobile-breakpoint="mobileBreakpoint"
								  :items-per-page="-1"
								  :options="options"
								  must-sort
								  @click:row="handleItemSelect"
								  class="loan-table bod-table hr-table d-flex flex-column clickable-rows">
						<template #item.approvedEmployees="{ item }">
							<div v-if="item.approvedEmployees.length" class="d-flex flex-column">
								<span v-for="name in item.approvedEmployees">{{ name }}</span>
							</div>
							<span v-else>-</span>
						</template>

						<template #item.awaitingApprovalEmployees="{ item }">
							<div v-if="item.state === ApiHrVacationApplicationStateEnum.InReview && item.awaitingApprovalEmployees.length"
								 class="d-flex flex-column">
								<span v-for="name in item.awaitingApprovalEmployees">{{ name }}</span>
							</div>
							<span v-else>-</span>
						</template>

						<template #item.state="{ item }">
							<v-progress-circular v-if="loadingCalendarApplicationId === item.id" width="2" indeterminate />
							<hr-vacation-application-state-label v-else @click.stop="handleOpenStateDialog(item)" :value="item.state" />
						</template>
						
						<template #item.documents="{ item }">
							<div v-if="item.documents.length" class="d-flex flex-column">
								<span @click.stop="handleOpenDocumentDialog(document)" v-for="document in item.documents" :key="document.id"
									  class="text-decoration-underline">
									{{ document.title }}
								</span>
							</div>
							<span v-else>-</span>
						</template>
					</v-data-table>
				</v-col>
			</v-row>
		</v-card>
		
		<hr-application-state-dialog v-model="isStateDialogOpened"></hr-application-state-dialog>
		<hr-application-document-dialog v-model="isDocumentDialogOpened"></hr-application-document-dialog>
		
		<frp-bottom-space height="24"></frp-bottom-space>
	</hr-content-layout>
</template>

<script>
import { ApiHrVacationApplicationStateEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationApplicationStateEnum";
import { ApiHrVacationTypeEnum } from "@/api/hr/types/vacationApplication/ApiHrVacationTypeEnum";
import FrpFile from "@/components/common/FrpFile.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import HrVacationApplicationStateLabel from "@/components/labels/HrVacationApplicationStateLabel.vue";
import { externalUrls } from "@/constants/hr/externalUrls";
import HrVacationApplicationsFilter
	from "@/store/hr/modules/vacationApplications/types/hrVacationApplicationsFilter";
import storeManager from "@/store/manager";
import HrApplicationDocumentDialog from "@/views/hr/vacationApplications/dialogs/HrApplicationDocumentDialog.vue";
import HrApplicationStateDialog from "@/views/hr/vacationApplications/dialogs/HrApplicationStateDialog.vue";
import FrpLinkBtn from "@/components/buttons/FrpLinkBtn.vue";
import FrpRouterLinkBtn from "@/components/buttons/FrpRouterLinkBtn.vue";
import FrpPagination from "@/components/common/FrpPagination.vue";
import BodAutocomplete from "@/components/fields/BodAutocomplete.vue";
import FrpTreeAutocomplete from "@/components/fields/FrpTreeAutocomplete.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import BodContentLayout from "@/components/layouts/BodContentLayout.vue";
import FrpBottomSpace from "@/components/layouts/FrpBottomSpace.vue";
import HrContentLayout from "@/components/layouts/HrContentLayout.vue";
import { getYear } from "date-fns";
import { assign, isEqual, first } from "lodash";
import authorizationMixin from "@/mixins/authorizationMixin";
import { RouteNames } from "@/router/hr/routes";
import { actionTypes, getterTypes, mutationTypes } from "@/store/hr/modules/vacationApplications/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "@/mixins/listMixin";
import colorsMixin from "@/mixins/colorsMixin";
import storeModuleBasedPage from "@/mixins/storeModuleBasedPage";
import FrpAlert from "@/components/alerts/FrpAlert.vue";
import FrpAlerts from "@/components/alerts/FrpAlerts.vue";
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import FrpCheckbox from "@/components/fields/FrpCheckbox.vue";
import FrpTextField from "@/components/fields/FrpTextField.vue";
import BodFooter from "@/components/layouts/BodFooter.vue";
import BodMain from "@/components/layouts/BodMain.vue";

const namespace = storeManager.hr.vacationApplications.namespace;
const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	data() {
		return {
			RouteNames,
			ApiHrVacationApplicationStateEnum,
			namespace,
			internalFilterValues: {
				vacationTypes: [],
				applicationStates: [],
				employeeIds: [],
				isAllEmployees: false
			},
			vacationTypes: Object.values(ApiHrVacationTypeEnum).map(x => ({ text: this.$t(`aliases.vacationType.${x}`), value: x })),
			applicationStates: Object.values(ApiHrVacationApplicationStateEnum)
									 .map(x => ({ text: this.$t(`aliases.vacationApplicationState.${x}`), value: x })),
			isStateDialogOpened: false,
			isDocumentDialogOpened: false
		};
	},
	computed: {
		externalUrls() {
			return externalUrls;
		},
		...mapState({
			initialized: state => state.isInitialized,
			isEmployeesLoading: state => state.isEmployeesLoading,
			loadingCalendarApplicationId: state => state.loadingCalendarApplicationId,
			employees: state => state.employees
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			isNoData: getterTypes.isListingEmpty,
			isAdmin: getterTypes.isAdmin,
			currentUser: getterTypes.currentUser
		}),
		headers() {
			const headers = [
				{
					text: this.$t("tables.createdAt"),
					value: "createdAt",
					class: "text-caption",
					width: "9%",
					sortable: false
				},
				{
					text: this.$t("tables.vacationType"),
					value: "formattedType",
					class: "text-caption",
					width: "11%",
					sortable: false
				},
				{
					text: this.$t("tables.employee"),
					value: "employeeName",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.period"),
					value: "period",
					class: "text-caption",
					width: "11%",
					sortable: false
				},
				{
					text: this.$t("tables.substituteEmployee"),
					value: "substituteName",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.approved"),
					value: "approvedEmployees",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.awaitingApproval"),
					value: "awaitingApprovalEmployees",
					class: "text-caption",
					width: "12%",
					sortable: false
				},
				{
					text: this.$t("tables.status"),
					value: "state",
					class: "text-caption",
					width: "9%",
					sortable: false
				},
				{
					text: this.$t("tables.documents"),
					value: "documents",
					class: "text-caption",
					width: "11%",
					sortable: false
				}
			];
			
			return this.isAdmin ? headers : headers.filter(x => x.value !== "employeeName");
		},
		filter() {
			return {
				vacationTypes: this.internalFilterValues.vacationTypes,
				applicationStates: this.internalFilterValues.applicationStates,
				employeeIds: this.internalFilterValues.employeeIds
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(new HrVacationApplicationsFilter(this.isAdmin ? [this.currentUser.id] : []), this.filterValues);
		}
	},
	methods: {
		...mapMutations({
			setFilterVacationTypes: mutationTypes.SET_FILTER_VACATION_TYPES,
			setFilterApplicationStates: mutationTypes.SET_FILTER_APPLICATION_STATES,
			setFilterEmployeeIds: mutationTypes.SET_FILTER_EMPLOYEE_IDS,
			setFilterIsAllEmployees: mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES,
			setFilter: mutationTypes.SET_FILTER,
			setCurrentOpenedDocument: mutationTypes.SET_CURRENT_OPENED_DOCUMENT,
			setCurrentOpenedApplication: mutationTypes.SET_CURRENT_OPENED_APPLICATION
		}),
		...mapActions({
			fetchCalendar: actionTypes.fetchCalendar,
		}),
		handleItemSelect({ id }) {
			if(id)
				this.$router.push({ name: RouteNames.VACATION_APPLICATION, params: { id } });
		},
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		resetFilter() {
			const defaults = new HrVacationApplicationsFilter(this.isAdmin ? [this.currentUser.id] : []);
			this.setFilter(defaults);
		},
		applyFilter() {
			this.setFilterVacationTypes(this.internalFilterValues.vacationTypes || []);
			this.setFilterApplicationStates(this.internalFilterValues.applicationStates || []);
			this.setFilterEmployeeIds(this.internalFilterValues.employeeIds || []);
		},
		async handleOpenStateDialog(application) {
			if(this.loadingCalendarApplicationId === application.id)
				return;
			
			await this.fetchCalendar(application);
			this.setCurrentOpenedApplication(application);
			this.isStateDialogOpened = true;
		},
		handleOpenDocumentDialog(document) {
			this.setCurrentOpenedDocument(document);
			this.isDocumentDialogOpened = true;
		}
	},
	created() {
		this.initializeStore();
	},
	watch: {
		"filterValues.employeeIds"(value, prev) {
			if(!value.length && !!prev.length) {
				this.setFilterIsAllEmployees(true);
			} else if(!!value.length && !prev.length) {
				this.setFilterIsAllEmployees(false);
			}
		}
	},
	components: {
		HrApplicationDocumentDialog,
		HrApplicationStateDialog,
		FrpFile,
		HrVacationApplicationStateLabel,
		FrpDateField,
		FrpTextarea,
		FrpTreeAutocomplete,
		FrpRouterLinkBtn,
		FrpLinkBtn,
		FrpBottomSpace,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter,
		BodContentLayout,
		HrContentLayout
	}
};
</script>
