<template>
	<div class="d-flex flex-wrap frp-calendar frp-year-calendar" :class="`calendar--${color} ${readonly && 'frp-year-calendar--readonly'}`"
		 style="gap: 35px 30px"
		 v-if="year"
		 ref="calendar">
		<v-date-picker v-for="i in 12"
					   :key="i"
					   :disabled="disabled"
					   :readonly="readonly"
					   @click:date="onClickDate"
					   no-title
					   :multiple="multiple"
					   :range="range"
					   :allowed-dates="checkIsAllowed"
					   first-day-of-week="1"
					   :header-date-format="getHeaderDateFormat"
					   v-model="internalValue"
					   :weekday-format="getWeekdayFormat"
					   :picker-date="`${year}-0${i}`"
					   :show-current="false"
					   show-adjacent-months>
		</v-date-picker>
	</div>
</template>

<script>
import { ApiCalendarDayTypeEnum } from "@/api/calendar/types/ApiCalendarDayTypeEnum";
import { CalendarDateColorEnum } from "@/types/calendar/CalendarDateColorEnum";
import { convertToTimestamp, formatDate, parseDate } from "@/utils/dates";
import { dayOfWeek, friendlyMonthFormat, isoDateFormat, isoYearMonthFormat } from "@/utils/formats";
import { addDays, differenceInDays, eachDayOfInterval, format, isSameDay, isWeekend, isWithinInterval, parse, startOfDay } from "date-fns";
import { ru } from "date-fns/locale";
import { isEqual } from "lodash";

export default {
	model: {
		prop: "value",
		event: "update:value"
	},
	props: {
		value: {
			type: Array,
			default: () => []
		},
		format: {
			type: String, // number, string
			default: "string"
		},
		year: [Number, String],
		disabledPeriods: {
			type: Array,
			default: () => []
		},
		calendarDates: {
			type: Array,
			default: () => []
		},
		multiple: Boolean,
		range: Boolean,
		disabled: Boolean,
		readonly: Boolean,
		maxDays: Number,
		color: {
			type: String, // red, blue, grey
			default: "red"
		}
	},
	data() {
		return {
			currentPeriod: []
		};
	},
	computed: {
		internalValue: {
			get() {
				if(this.format === "string") {
					if(!this.range)
						return this.value;
					else
						throw new Error("Одновременное использование range и format=string не поддерживается");
				} else if(this.format === "number") {
					if(!this.range)
						return this.value.map(x => formatDate(x, isoDateFormat));
					else {
						const dates = this.value.map(x => eachDayOfInterval({ start: x[0], end: x[1] }).map(x => formatDate(x,
							isoDateFormat)));
						
						// Пустые строки - фикс календаря, который две даты отображает как период
						return [...dates.flat(), ...this.currentPeriod, "", ""];
					}
				}
			},
			set(value) {
				if(this.format === "string") {
					if(!this.range)
						this.$emit("update:value", value);
					else
						throw new Error("Одновременное использование range и format=string не поддерживается");
				} else if(this.format === "number") {
					if(!this.range)
						this.$emit("update:value", value.map(x => convertToTimestamp(x)));
					else {
						const date = value.pop();
						
						if(this.getDatePeriod(date))
							return;
						
						this.currentPeriod.push(date);
						
						if(this.currentPeriod.length === 1)
							return;
						
						const period = this.currentPeriod.map(x => convertToTimestamp(x)).sort();
						
						this.$emit("add:period", period);
						this.currentPeriod = [];
					}
				}
			}
		},
		currentDaysCount() {
			if(!this.range)
				return;
			
			return this.value.map(([start, end]) => differenceInDays(end, start) + 1).reduce((acc, v) => acc + v, 0) -
				this.holidaysWithinPeriodsCount;
		},
		holidaysWithinPeriodsCount() {
			if(!this.range)
				return;
			
			return this.value.map(([start, end]) => this.holidays.filter(x => isWithinInterval(x.date, { start, end })).length).reduce((acc,
				v) => acc + v, 0);
		},
		holidays() {
			return this.calendarDates.filter(x => x.type === ApiCalendarDayTypeEnum.Holiday);
		},
		workingDays() {
			return this.calendarDates.filter(x => x.type === ApiCalendarDayTypeEnum.WorkingDay);
		},
		weekends() {
			return this.calendarDates.filter(x => x.type === ApiCalendarDayTypeEnum.Weekend);
		}
	},
	methods: {
		getHeaderDateFormat(date) {
			return format(parseDate(date, isoYearMonthFormat), friendlyMonthFormat, { locale: ru });
		},
		getWeekdayFormat(date) {
			return format(parseDate(date, isoDateFormat), dayOfWeek, { locale: ru });
		},
		onDocumentClick(evt) {
			if(!evt.target.classList.contains("v-btn__content") && this.currentPeriod.length)
				this.currentPeriod = [];
		},
		getDatePeriod(date) {
			if(typeof date === "string")
				return this.value.find(([start, end]) => isWithinInterval(convertToTimestamp(date), { start, end }));
			else if(typeof date === "number")
				return this.value.find(([start, end]) => isWithinInterval(date, { start, end }));
		},
		checkIsAllowed(date) {
			if(!this.range)
				return true;
			
			const timestamp = convertToTimestamp(date);
			
			const isLessThanToday = timestamp >= startOfDay(new Date()).getTime();
			const isWithinPeriodDay = this.value.some(([start, end]) => isWithinInterval(timestamp, { start, end }));
			
			// Если пользователь в данный момент не выбирает конец периода,
			// то доступны все даты, кроме прошедшей, и любые дни (в т.ч. прошедшие), для которых уже есть периоды
			if(this.currentPeriod.length !== 1)
				return this.readonly || (isWithinPeriodDay || isLessThanToday);
			
			const currentDate = convertToTimestamp(this.currentPeriod[0]);
			
			// Если дата меньше первой даты периода, то она недоступна для выбора
			if(timestamp < currentDate)
				return false;
			
			let nextPeriod;
			this.value.forEach(([start, end]) => {
				const distance = start - currentDate;
				if(currentDate < start && (!nextPeriod || distance < nextPeriod[0] - currentDate))
					nextPeriod = [start, end];
			});
			
			const holidaysCount = this.holidays.filter(x => formatDate(x.date, isoDateFormat) !== date &&
				isWithinInterval(x.date, { start: currentDate, end: timestamp })).length;
			const isLessThanNextPeriod = !nextPeriod || timestamp < nextPeriod[0];
			const isLessThanMaxDays = addDays(currentDate, this.maxDays - this.currentDaysCount + holidaysCount) > timestamp;
			
			// Если дата меньше даты начала следующего периода (если он есть)
			// и период с ней не превышает максимальное количество дней для всех периодов (не считая праздники), то дата доступна для выбора
			return isLessThanNextPeriod && isLessThanMaxDays;
		},
		onClickDate(date) {
			if(this.readonly || !this.range)
				return;
			
			const timestamp = convertToTimestamp(date);
			const isWithinDisabledPeriod = this.disabledPeriods.some(([start, end]) => isWithinInterval(timestamp, { start, end }));
			
			if(isWithinDisabledPeriod)
				return;
			
			const period = this.getDatePeriod(date);
			
			if(period)
				return this.$emit("click:period", period);
		},
		markEmptyWeeks() {
			const months = this.$refs.calendar.querySelectorAll("tbody");
			
			months.forEach(x => {
				const btn = x.querySelector("tr:last-child .v-btn__content");
				
				if(btn.textContent === "1")
					btn.classList.add("empty-week");
			});
		},
		processDates() {
			const dateButtons = document.querySelectorAll(".v-date-picker-table button");
			
			dateButtons.forEach(el => {
				const monthName = el.closest(".v-picker__body").querySelector(".v-date-picker-header__value button").innerHTML;
				const day = el.querySelector(".v-btn__content").innerHTML;
				const date = parse(`${day} ${monthName}`, "d LLLL", new Date().setFullYear(this.year), { locale: ru });
				
				if(el.disabled)
					return;
				
				if(this.disabledPeriods?.length) {
					const isWithinDisabledPeriods = this.disabledPeriods.some(([start, end]) => isWithinInterval(date.getTime(),
						{ start, end }));
					
					if(isWithinDisabledPeriods) {
						el.title = this.$t("tooltips.vacationHasApplication");
						el.style.cursor = "default";
					}
				}
				
				if(this.calendarDates?.length) {
					const isDateWeekend = isWeekend(date);
					const calendarDate = this.calendarDates.find(x => isSameDay(date, x.date));
					const color = calendarDate?.color || (isDateWeekend && CalendarDateColorEnum.RED);
					
					if(color)
						el.classList.add(`frp-calendar-date--${color}`);
					if(calendarDate?.type === ApiCalendarDayTypeEnum.Holiday)
						el.classList.add(`frp-calendar-date--disabled`);
				}
			});
		}
	},
	async mounted() {
		this.markEmptyWeeks();
		this.processDates();
	},
	watch: {
		async currentPeriod(value) {
			if(value?.length === 1)
				document.addEventListener("click", this.onDocumentClick);
			else
				document.removeEventListener("click", this.onDocumentClick);
		},
		async internalValue(value, prev) {
			// Отрисовка праздников, выходных и рабочих выходных после изменения выбранных периодов
			if(isEqual(value, prev) || this.currentPeriod.length)
				return;
			
			await this.$nextTick();
			this.processDates();
		},
		calendarDates(value) {
			if(value?.length)
				this.processDates();
		}
	}
};
</script>

<style lang="scss">
.frp-year-calendar {
  .v-picker__body {
	width: fit-content !important;
  }

  table {
	width: auto;
	border-spacing: 2px 5px;
  }

  .v-date-picker-header {
	padding: 0;

	& > button {
	  display: none;
	}

	.v-date-picker-header__value {
	  pointer-events: none;

	  & > div {
		text-align: start;
	  }

	  button {
		text-transform: capitalize;
		font-weight: 400;
		font-size: 15px;
		line-height: 18px;
		padding: 8px 12px;
		color: var(--v-primary-darken1) !important;
	  }
	}
  }

  .v-date-picker-table {
	height: fit-content;
	padding: 0;

	th {
	  text-transform: capitalize;
	  padding: 3px 0;
	  font-weight: 400;
	  font-size: 14px;
	  color: var(--v-primary-darken1);
	}

	th:nth-child(6), th:nth-child(7) {
	  color: var(--v-red-base);
	}

	td button {
	  border-radius: 0;
	  width: 24px;
	  height: 24px;
	  font-size: 13px;
	  color: var(--v-primary-darken1) !important;

	  &.v-btn--active {
		background-color: unset !important;

		.v-btn__content {
		  order: 10;
		}

		&::after {
		  width: 100%;
		  height: 100%;
		  position: absolute;
		  content: "";
		}
	  }

	  &.v-btn--disabled {
		color: var(--v-grey-lighten5) !important;
	  }
	}

	tr:has(.empty-week) {
	  display: none;
	}
  }

  tbody td button {
	&:focus::before, &.v-btn--active::before {
	  opacity: 0 !important;
	}

	&:has(.v-date-picker-table__events) {
	  &:not(.v-btn--active) {
		pointer-events: none;
	  }

	  color: var(--v-secondary-lighten2) !important;
	}

	.v-date-picker-table__events {
	  display: none;
	}
  }

  &.calendar--red {
	.v-btn--active {
	  &::after {
		background: var(--v-red-base);
		opacity: 0.3;
	  }

	  &.v-btn--disabled::after {
		opacity: 0.1;
	  }
	}
  }

  &.calendar--blue {
	.v-btn--active {
	  &::after {
		background: var(--v-blue-lighten1);
		opacity: 0.3;
	  }

	  &.v-btn--disabled::after {
		opacity: 0.1;
	  }
	}
  }

  &.calendar--grey {
	.v-btn--active {
	  &::after {
		background: var(--v-grey-base);
		opacity: 0.3;
	  }

	  &.v-btn--disabled::after {
		opacity: 0.1;
	  }
	}
  }

  &--readonly {
	tbody .v-btn {
	  pointer-events: none;
	}
  }
}
</style>
