<template>
	<hr-content-layout container-class="pt-1 pt-lg-2 px-4 px-lg-4" bg-height="0">
		<v-card elevation="0" class="kpi-card px-6 py-6 mb-6">
			<div class="d-flex flex-wrap" style="gap: 16px">
				<div class="d-flex align-center">
					<bod-autocomplete class="kpi-field"
									  hide-details
									  :loading="isYearsLoading"
									  style="width: 110px"
									  :items="yearValues"
									  required
									  v-model="internalFilterValues.year"
									  color="blue"
									  :placeholder="$t('fields.kpiYear.placeholder')">
					</bod-autocomplete>
					
					<frp-btn :disabled="!!years.length && (!filterValues.year || filterValues.year !== first(yearValues))"
							 @click="createYear"
							 :loading="isYearCreating"
							 class="ml-2" icon :color="colors.blue.base" style="width: 40px">
						<frp-icon src="ico_plus-circle"></frp-icon>
					</frp-btn>
				</div>
				
				<v-spacer></v-spacer>
				
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty"
						 color="primary"
						 outlined
						 elevation="0">
					{{ $t("buttons.reset") }}
				</frp-btn>
				
				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged"
						 no-margin
						 color="blue"
						 dark
						 elevation="0">
					{{ $t("buttons.apply") }}
				</frp-btn>
			</div>
		</v-card>
		
		<v-card elevation="0"
				class="kpi-card pa-8 pt-4" v-if="filterValues.year">
			<div style="height: 2px" class="mb-2">
				<v-progress-linear v-if="isCalendarLoading || isCalendarUpdating" height="2" indeterminate></v-progress-linear>
			</div>
			
			<frp-calendar v-if="initialized" :calendar-dates="calendarDates" readonly :year="filterValues.year" multiple></frp-calendar>
			
			<div class="d-flex justify-end mt-8">
				<frp-btn :loading="isCalendarUpdating"
						 @click="fetchCalendar(true)"
						 :disabled="!initialized || isCalendarLoading"
						 color="blue" dark elevation="0">
					{{ $t("buttons.update") }}
				</frp-btn>
			</div>
		</v-card>
	</hr-content-layout>
</template>

<script>
import FrpCalendar from "@/components/common/FrpCalendar.vue";
import VacationPlanCalendarFilter from "@/store/hr/modules/vacationPlanCalendar/types/vacationPlanCalendarFilter";
import FrpLinkBtn from "Components/buttons/FrpLinkBtn";
import FrpRouterLinkBtn from "Components/buttons/FrpRouterLinkBtn";
import FrpPagination from "Components/common/FrpPagination";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpTreeAutocomplete from "Components/fields/FrpTreeAutocomplete";
import FrpIcon from "Components/icon/FrpIcon";
import BodContentLayout from "Components/layouts/BodContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import HrContentLayout from "Components/layouts/HrContentLayout";
import { assign, isEqual, first } from "lodash";
import authorizationMixin from "Mixins/authorizationMixin";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "../../../store/hr/modules/vacationPlanCalendar";
import { actionTypes, getterTypes, mutationTypes } from "@/store/hr/modules/vacationPlanCalendar/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpAutocomplete from "Components/fields/FrpAutocomplete";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage, authorizationMixin],
	metaInfo() {
		return {
			title: this.$t("titles.vacationPlanAdministration")
		};
	},
	data() {
		return {
			RouteNames,
			namespace,
			first,
			internalFilterValues: {
				year: undefined
			}
		};
	},
	computed: {
		...mapState({
			state: state => state,
			calendarDates: state => state.calendarDates,
			initialized: state => state.isInitialized,
			isYearsLoading: state => state.isYearsLoading,
			isYearCreating: state => state.isYearCreating,
			isCalendarLoading: state => state.isCalendarLoading,
			isCalendarUpdating: state => state.isCalendarUpdating,
			years: state => state.years
		}),
		...mapGetters({
			yearValues: getterTypes.yearValues
		}),
		filter() {
			return {
				year: this.internalFilterValues.year
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues).map(([k, v]) => [k, v === null ? [] : v])),
				assign({}, this.filterValues));
		},
		isFilterEmpty() {
			return isEqual(new VacationPlanCalendarFilter(first(this.yearValues)), this.filterValues);
		}
	},
	methods: {
		...mapMutations({
			setFilterYear: mutationTypes.SET_FILTER_YEAR,
			resetFilter: mutationTypes.RESET_FILTER
		}),
		...mapActions({
			createYear: actionTypes.createYear,
			fetchCalendar: actionTypes.fetchCalendar
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		applyFilter() {
			this.setFilterYear(this.internalFilterValues.year);
		}
	},
	created() {
		this.initializeStore();
	},
	components: {
		FrpCalendar,
		FrpTreeAutocomplete,
		FrpRouterLinkBtn,
		FrpLinkBtn,
		FrpBottomSpace,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		FrpAutocomplete,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter,
		BodContentLayout,
		HrContentLayout
	}
};
</script>
