import {
	namespace,
	actionTypes,
	mutationTypes,
	getterTypes
} from "@/store/hr/modules/employee/modules/subordinates/types";
import BaseMixinBuilder from "@/store/shared/base";
import StateManipulationMixinBuilder from "@/store/shared/stateManipulation";
import SortingMixinBuilder from "@/store/shared/sorting";
import SortingModel from "@/store/shared/sorting/models/sortingModel";
import ListingMixinBuilder from "@/store/shared/listing";
import ListingModel from "@/store/shared/listing/models/listingModel";
import PagingMixinBuilder from "@/store/shared/paging";
import PagingModel from "@/store/shared/paging/models/pagingModel";
import SearchMixinBuilder from "@/store/shared/search";
import SearchModel from "@/store/shared/search/models/searchModel";
import { actionTypes as rootActionTypes } from "@/store/hr/types";
import { sortingOrderType } from "@/store/shared/sorting/models/types/sortingOrderType";
import { ActionTree, GetterTree, MutationTree } from "vuex";
import AbortService from "@/services/abortService";
import { HrController } from "@/api/hr";
import EmployeeSubordinatesState from "@/store/hr/modules/employee/modules/subordinates/types/employeeSubordinatesState";
import router from "@/router/hr";
import { KpiController } from "@/api/kpi";
import { getQuarter, getYear, subQuarters } from "date-fns";
import { HrSubordinate } from "@/types/hr/hrSubordinate";
import { SubordinatesTableValueState } from "@/store/hr/modules/employee/modules/subordinates/types/subordinatesTableValueState";
import { HrSubordinatesTableValueTypeEnum } from "@/store/hr/modules/employee/modules/subordinates/types/hrSubordinatesTableValueTypeEnum";
import { HrTableErrorMapper } from "@/types/hr/hrTableError";

const abortService = new AbortService();
const hrController = new HrController(abortService);
const kpiController = new KpiController(abortService);

class DefaultStateBuilder {
	constructor() {
	}
	
	build() {
		return new EmployeeSubordinatesState(
			new ListingModel<HrSubordinate>({
				items: [],
				isLoadingState: false
			}),
			new SortingModel<String>({
				type: "",
				order: sortingOrderType.descending
			}),
			new PagingModel({
				total: 0,
				page: 1,
				pageSize: 25
			}),
			new SearchModel({
				query: ""
			})
		);
	}
}

const stateManipulationMixin = (new StateManipulationMixinBuilder({
	defaultStateBuilder: new DefaultStateBuilder()
})).build();
const baseMixin = (new BaseMixinBuilder(abortService)).build();
const listingMixin = (new ListingMixinBuilder()).build();
const pagingMixin = (new PagingMixinBuilder()).build();
const sortingMixin = (new SortingMixinBuilder()).build();
const searchMixin = (new SearchMixinBuilder()).build();

const state = (new DefaultStateBuilder()).build();

const getters = <GetterTree<EmployeeSubordinatesState, any>>{
	...listingMixin.getters,
	[getterTypes.formattedItems]: state => {
		return state.listing.items.map(x => {
			let item = {
				...x,
				vacationRemainingDays: x.vacation?.remainingDays,
				standardAwareness: x.standardAwarenessRaw?.correctAnswersRate,
				discipline: x.disciplineRaw?.discipline
			};
			
			return item;
		});
	}
};

const actions = <ActionTree<EmployeeSubordinatesState, any>>{
	...baseMixin.actions,
	...stateManipulationMixin.actions,
	...listingMixin.actions,
	...pagingMixin.actions,
	...sortingMixin.actions,
	...searchMixin.actions,
	async [actionTypes.initialize]({ dispatch, commit, state }) {
		await dispatch(actionTypes.initializeBase);
		
		await dispatch(actionTypes.updateListingItems);
		
		commit(mutationTypes.SET_IS_INITIALIZED, true);
		
		await dispatch(actionTypes.fetchValues);
	},
	async [actionTypes.updateListingItems]({ commit, state, dispatch }) {
		commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, true);
		
		try {
			let apiEmployees = await hrController.getSubordinates(router.currentRoute.params.id);
						
			const subordinates = apiEmployees.map((x, i) => ({
				...x,
				kpi: undefined,
				standardAwarenessRaw: undefined,
				disciplineRaw: undefined,
				vacation: undefined
			})) as HrSubordinate[];
			
			commit(mutationTypes.SET_LISTING_ITEMS, subordinates);
		} catch (error) {
			dispatch(rootActionTypes.handleServerError, error, { root: true });
		} finally {
			commit(mutationTypes.SET_IS_LISTING_ITEMS_LOADING_STATE, false);
		}
	},
	async [actionTypes.fetchValues]({ commit, state, dispatch }, id: string) {
		const values: SubordinatesTableValueState[] = [];
		const tasks: Promise<void>[] = [];
		
		state.listing.items.forEach(x => {
			// Инициализация состояния значений таблицы, загружаемых отдельно
			values.push(...[
				{ type: HrSubordinatesTableValueTypeEnum.KPI, employeeId: x.id },
				{ type: HrSubordinatesTableValueTypeEnum.STANDARD, employeeId: x.id },
				{ type: HrSubordinatesTableValueTypeEnum.DISCIPLINE, employeeId: x.id },
				{ type: HrSubordinatesTableValueTypeEnum.VACATION, employeeId: x.id }
			]);
		});
		
		commit(mutationTypes.SET_VALUES_STATE, values);
		
		state.listing.items.forEach(x => {
			tasks.push(...[
				// Закомментировано в рамках задачи 79143, LOAN-1818 на время доработки
				// dispatch(actionTypes.fetchKpi, x.id),
				// dispatch(actionTypes.fetchStandard, x.id),
				// dispatch(actionTypes.fetchDiscipline, x.id),
				dispatch(actionTypes.fetchVacation, x.id)
			]);
		});
		
		await Promise.all(tasks);
	},
	async [actionTypes.fetchKpi]({ commit }, id: string) {
		commit(mutationTypes.SET_IS_VALUE_LOADING, { id, type: HrSubordinatesTableValueTypeEnum.KPI, value: true });
		
		try {
			const date = subQuarters(new Date(), 1);
			
			const res = await kpiController.getEmployeeReportingByQuarter(getYear(date), getQuarter(date), id);
			
			commit(mutationTypes.SET_LISTING_ITEMS_KPI, { id, value: res?.staffs[0] });
		} catch (error) {
			const data = error.response?.data;
			commit(mutationTypes.SET_VALUE_ERROR, { id, type: HrSubordinatesTableValueTypeEnum.KPI,
				value: HrTableErrorMapper.map(data?.title || error.title, data?.detail || error.message)
			});
		} finally {
			commit(mutationTypes.SET_IS_VALUE_LOADING, { id, type: HrSubordinatesTableValueTypeEnum.KPI, value: false });
		}
	},
	async [actionTypes.fetchStandard]({ commit }, id: string) {
		commit(mutationTypes.SET_IS_VALUE_LOADING, { id, type: HrSubordinatesTableValueTypeEnum.STANDARD, value: true });
		
		try {
			const res = await hrController.getEmployeeStandard(id);
			
			commit(mutationTypes.SET_LISTING_ITEMS_STANDARD, { id, value: res });
		} catch (error) {
			const data = error.response?.data;
			commit(mutationTypes.SET_VALUE_ERROR, { id, type: HrSubordinatesTableValueTypeEnum.STANDARD,
				value: HrTableErrorMapper.map(data?.title || error.title, data?.detail || error.message)
			});
		} finally {
			commit(mutationTypes.SET_IS_VALUE_LOADING, { id, type: HrSubordinatesTableValueTypeEnum.STANDARD, value: false });
		}
	},
	async [actionTypes.fetchDiscipline]({ commit }, id: string) {
		commit(mutationTypes.SET_IS_VALUE_LOADING, { id, type: HrSubordinatesTableValueTypeEnum.DISCIPLINE, value: true });
		
		try {
			const res = await hrController.getEmployeeDiscipline(id);
			
			commit(mutationTypes.SET_LISTING_ITEMS_DISCIPLINE, {
				id,
				value: res && {
					...res,
					discipline: res.discipline && Math.floor(res.discipline)
				}
			});
		} catch (error) {
			const data = error.response?.data;
			commit(mutationTypes.SET_VALUE_ERROR, { id, type: HrSubordinatesTableValueTypeEnum.DISCIPLINE,
				value: HrTableErrorMapper.map(data?.title || error.title, data?.detail || error.message)
			});
		} finally {
			commit(mutationTypes.SET_IS_VALUE_LOADING, { id, type: HrSubordinatesTableValueTypeEnum.DISCIPLINE, value: false });
		}
	},
	async [actionTypes.fetchVacation]({ commit }, id: string) {
		commit(mutationTypes.SET_IS_VALUE_LOADING, { id, type: HrSubordinatesTableValueTypeEnum.VACATION, value: true });
		
		try {
			const res = await hrController.getEmployeeVacation(id);
			
			commit(mutationTypes.SET_LISTING_ITEMS_VACATION, { id, value: res });
		} catch (error) {
			const data = error.response?.data;
			commit(mutationTypes.SET_VALUE_ERROR, { id, type: HrSubordinatesTableValueTypeEnum.VACATION,
				value: HrTableErrorMapper.map(data?.title || error.title, data?.detail || error.message)
			});
		} finally {
			commit(mutationTypes.SET_IS_VALUE_LOADING, { id, type: HrSubordinatesTableValueTypeEnum.VACATION, value: false });
		}
	}
};

const mutations = <MutationTree<EmployeeSubordinatesState>>{
	...baseMixin.mutations,
	...stateManipulationMixin.mutations,
	...listingMixin.mutations,
	...pagingMixin.mutations,
	...sortingMixin.mutations,
	...searchMixin.mutations,
	[mutationTypes.SET_VALUES_STATE](state, value) {
		state.valuesState = value;
	},
	[mutationTypes.SET_IS_VALUE_LOADING](state, { id, type, value }) {
		state.valuesState.find(x => x.employeeId === id && x.type === type)!.loading = value;
	},
	[mutationTypes.SET_VALUE_ERROR](state, { id, type, value }) {
		state.valuesState.find(x => x.employeeId === id && x.type === type)!.error = value;
	},
	[mutationTypes.SET_LISTING_ITEMS_KPI](state, { id, value }) {
		state.listing.items.find(x => x.id === id)!.kpi = value;
	},
	[mutationTypes.SET_LISTING_ITEMS_STANDARD](state, { id, value }) {
		state.listing.items.find(x => x.id === id)!.standardAwarenessRaw = value;
	},
	[mutationTypes.SET_LISTING_ITEMS_DISCIPLINE](state, { id, value }) {
		state.listing.items.find(x => x.id === id)!.disciplineRaw = value;
	},
	[mutationTypes.SET_LISTING_ITEMS_VACATION](state, { id, value }) {
		state.listing.items.find(x => x.id === id)!.vacation = value;
	}
};

export {
	namespace, state, getters, actions, mutations
};

const employeeSubordinatesModule = {
	namespace, state, getters, actions, mutations, namespaced: true
};

export default employeeSubordinatesModule;
