<template>
	<div>
		<v-card elevation="0" class="kpi-card mb-3">
			<frp-gantt-year @click="handleOpenApproveDialog" :items="ganttFormattedItems" :year="this.filter.year"></frp-gantt-year>
			
			<frp-btn @click="handleOpenMassApproveDialog"
					 v-if="hasFirstInReviewPlans && can([Permissions.HR_VACATIONS_PLAN_APPROVE, Permissions.HR_VACATIONS_PLAN_DECLINE])"
					 color="blue" no-margin dark elevation="0" class="my-6 mx-3">
				{{ $t("buttons.employeesMassApprove") }}
			</frp-btn>
		</v-card>
		
		<v-card elevation="0" class="kpi-card">
			<frp-gantt-month @click="handleOpenApproveDialog"
							 :items="ganttFormattedItems"
							 :calendar-dates="calendarDates"
							 :year="this.filter.year"
							 :month="this.filter.month ? this.filter.month - 1 : 0">
			</frp-gantt-month>
		</v-card>
		
		<hr-mass-approve-dialog v-model="isMassApproveDialogOpened"></hr-mass-approve-dialog>
		<hr-approve-dialog @decline="handleOpenDeclineReasonDialog" v-model="isApproveDialogOpened"></hr-approve-dialog>
		<hr-decline-reason-dialog v-model="isDeclineReasonDialogOpened"></hr-decline-reason-dialog>
	</div>
</template>

<script>
import FrpCalendar from "@/components/common/FrpCalendar.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpGanttMonth from "@/components/gantt/FrpGanttMonth.vue";
import FrpGanttYear from "@/components/gantt/FrpGanttYear.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import authorizationMixin from "@/mixins/authorizationMixin";
import { namespace as userNamespace } from "@/store/hr/modules/user";
import { VacationPlansSnapshotKeysEnum } from "@/store/hr/modules/vacationPlans/types/VacationPlansSnapshotKeysEnum";
import { HrVacationPlanStateEnum } from "@/types/hr/vacation/HrVacationPlanStateEnum";
import HrVacationPlansPlanTitle from "@/views/hr/vacationPlans/components/HrVacationPlansPlanTitle.vue";
import HrVacationPlansProgressBar from "@/views/hr/vacationPlans/components/HrVacationPlansProgressBar.vue";
import HrApproveDialog from "@/views/hr/vacationPlans/dialogs/HrApproveDialog.vue";
import HrDeclineReasonDialog from "@/views/hr/vacationPlans/dialogs/HrDeclineReasonDialog.vue";
import HrMassApproveDialog from "@/views/hr/vacationPlans/dialogs/HrMassApproveDialog.vue";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import { isArray } from "lodash";
import { RouteNames } from "Router/hr/routes";
import { Permissions } from "@/constants/permissions";
import { namespace } from "Store/hr/modules/vacationPlans";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlans/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import HrUpdateVacationDialog from "Views/hr/vacationPlans/dialogs/HrUpdateVacationDialog";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const { mapState: mapUserState } = createNamespacedHelpers(userNamespace);

export default {
	mixins: [colorsMixin, authorizationMixin],
	data() {
		return {
			RouteNames,
			VacationPlansSnapshotKeysEnum,
			Permissions,
			namespace,
			isMassApproveDialogOpened: false,
			isApproveDialogOpened: false,
			isDeclineReasonDialogOpened: false
		};
	},
	computed: {
		...mapUserState({
			user: state => state.user
		}),
		...mapState({
			filter: state => state.filter,
			calendarDates: state => state.calendarDates
		}),
		...mapGetters({
			filteredFormattedItems: getterTypes.filteredFormattedItems,
			currentUserPermissions: getterTypes.currentUserPermissions,
			canAcceptOwnPlan: getterTypes.canAcceptOwnPlan
		}),
		ganttFormattedItems() {
			return this.filteredFormattedItems.map(x => {
				const approvedPeriods = x.vacationsPlans.find(x => x.state === HrVacationPlanStateEnum.APPROVED)?.plannedVacations || [];
				let inReviewPeriods = x.vacationsPlans.find(x => x.state === HrVacationPlanStateEnum.IN_REVIEW)?.plannedVacations || [];
				const approvedPlan = (x.vacationPlan?.state === HrVacationPlanStateEnum.APPROVED) ? x.vacationPlan : undefined;
				
				if(approvedPlan) {
					const checkIsWithinApprovedPlan = ({ startDate, endDate }) => {
						return approvedPlan.plannedVacations.some(x => startDate === x.startDate && endDate === x.endDate);
					};
					
					inReviewPeriods = inReviewPeriods.filter(x => !checkIsWithinApprovedPlan(x));
				}
				
				return {
					id: x.employee.id,
					fullName: x.employee.fullName,
					periods: [
						...approvedPeriods.map(x => ({ ...x, isApproved: true })),
						...inReviewPeriods.map(x => ({ ...x, isApproved: false }))
					]
				};
			}).filter(x => x.periods.length);
		},
		hasFirstInReviewPlans() {
			const items = this.filteredFormattedItems.filter(x => {
				return this.canAcceptOwnPlan || (x.employee.id !== this.user.id);
			});

			return !!items.length && items.some(x => x.vacationsPlans.length === 1 &&
				x.vacationsPlans.some(y => y.state === HrVacationPlanStateEnum.IN_REVIEW));
		}
	},
	methods: {
		...mapMutations({
			setAcceptPlanRequestEmployeeId: mutationTypes.SET_ACCEPT_PLAN_REQUEST_EMPLOYEE_ID,
			setAcceptPlanRequestPlanId: mutationTypes.SET_ACCEPT_PLAN_REQUEST_PLAN_ID
		}),
		handleOpenMassApproveDialog() {
			this.isMassApproveDialogOpened = true;
		},
		handleOpenDeclineReasonDialog() {
			this.isDeclineReasonDialogOpened = true;
		},
		handleOpenApproveDialog(employeeId) {
			const item = this.filteredFormattedItems.find(x => x.employee.id === employeeId);
			const plan = item.vacationsPlans.find(x => x.state === HrVacationPlanStateEnum.IN_REVIEW) ||
				item.vacationsPlans.find(x => x.state === HrVacationPlanStateEnum.APPROVED);
			
			this.setAcceptPlanRequestEmployeeId(employeeId);
			this.setAcceptPlanRequestPlanId(plan.id);
			
			this.isApproveDialogOpened = true;
		},
		can(value) {
			if(isArray(value))
				return value.some(x => this.currentUserPermissions.includes(x));
			else
				return this.currentUserPermissions.includes(value);
		}
	},
	components: {
		FrpGanttMonth,
		FrpGanttYear,
		HrDeclineReasonDialog,
		HrApproveDialog,
		HrMassApproveDialog,
		HrVacationPlansPlanTitle,
		HrVacationPlansProgressBar,
		FrpIcon,
		FrpCalendar,
		FrpDateField,
		FrpTextarea,
		FrpBottomSpace,
		FrpBtn,
		HrUpdateVacationDialog
	}
};
</script>
