import { RouteNames } from "@/router/hr/routes";
import { Permissions } from "@/constants/permissions";

const routeToPermissionsMap = new Map<RouteNames, Permissions[]>([
		[RouteNames.ROOT, []],
		[RouteNames.CALLBACK, []],
		[RouteNames.SILENT_RENEW, []],
		[RouteNames.ERROR_PAGE_NOT_FOUND, []],
		[RouteNames.ERROR_ACCESS_DENIED, []],
		[RouteNames.ERROR_INTERNAL_SERVER_ERROR, []],
		
		[RouteNames.EMPLOYEES, []],
		[RouteNames.EMPLOYEE, []],
		
		[RouteNames.VACATION_PLANS, [Permissions.HR_VACATIONS_READ]],
		[RouteNames.VACATION_PLANS_ADMINISTRATION_EMPLOYEES, [Permissions.HR_VACATIONS_ADMINISTRATION_READ]],
		[RouteNames.VACATION_PLANS_ADMINISTRATION_CALENDAR, [Permissions.HR_VACATIONS_ADMINISTRATION_READ]],

		[RouteNames.VACATION_APPLICATIONS, [Permissions.HR_VACATION_APPLICATIONS_READ]],
		[RouteNames.VACATION_APPLICATIONS, []],
		[RouteNames.VACATION_APPLICATION_CREATE, []],
		[RouteNames.VACATION_APPLICATION, []],
		
		[RouteNames.ME, []],
		
		[RouteNames.TASKS, []]
	]
);

export default routeToPermissionsMap;
