import { ApiHrAvailableVacation } from "@/api/hr/types/vacationApplication/apiHrAvailableVacation";
import { HrAvailableVacation, HrAvailableVacationService } from "@/types/hr/vacationApplication/hrAvailableVacation";

export interface HrClosestVacation extends HrAvailableVacation {
	id: string;
	startDate: number;
	endDate: number;
	days: number;
	vacationsPlanId: string;
	deadline?: number;
}

export class HrClosestVacationService {
	static map(source: ApiHrAvailableVacation): HrClosestVacation {
		return {
			...HrAvailableVacationService.map(source)
		};
	}
}
