<template>
	<v-card elevation="0" class="kpi-card d-flex align-center py-4 px-6 mb-2 primary lighten-3">
		<div class="d-flex justify-space-between align-center flex-grow-1 mr-6 flex-wrap primary--text" style="gap: 8px">
			<div class="d-flex flex-column mr-4" style="max-width: 350px">
				<span style="font-size: 18px; font-weight: 600">{{ employee.fullName }}</span>
				<span style="font-size: 15px">{{ employee.department?.name }}</span>
			</div>
			<span>{{ employee.position }}</span>
			<span>{{ $t("common.vacationsDaysCount") }} - {{ totalVacationDays }}</span>
			<span>{{ $t("common.remainedVacationsDaysCount") }} - {{ totalVacationDays - (vacationPlan?.plannedVacationDays || 0) }}</span>
			<span>{{ $t("common.vacationPlanState") }} -
				<hr-vacation-plan-state-label v-if="vacationPlan"
											  @click="handleOpenVacationPlanApprovalDialog(vacationPlan.approvalId)"
											  :class="{ 'pointer': vacationPlan.approvalId && vacationPlan?.state === HrVacationPlanStateEnum.IN_REVIEW }"
											  :value="this.isEditMode ? HrVacationPlanStateEnum.DRAFT : vacationPlan.state">
				</hr-vacation-plan-state-label>
				<v-progress-circular v-if="loadingApprovalId === vacationPlan?.approvalId"
									 width="2" size="16" indeterminate class="ml-2">
				</v-progress-circular>
			</span>
		</div>
		
		<div class="ml-auto d-flex" style="gap: 8px">
			<frp-btn @click="handleEditPlan"
					 v-if="isPlanEditingAvailable"
					 color="blue" dark elevation="0" no-margin>
				{{ $t("buttons.updateVacationPlan") }}
			</frp-btn>
			<frp-btn @click="createFirstDraft(actionsPayload)"
					 v-if="isPlanCreationAvailable"
					 color="blue" dark elevation="0" no-margin>
				{{ $t("buttons.createFirstVacationPlan") }}
			</frp-btn>
			<frp-btn @click="deleteDraft"
					 v-if="isPlanDeletingAvailable"
					 :loading="isDraftDeleting"
					 :disabled="this.isStateContainsUnsavedChanges(VacationPlansSnapshotKeysEnum.NEW_VACATIONS)"
					 outlined color="secondary" elevation="0" no-margin>
				{{ $t("buttons.delete") }}
			</frp-btn>
			<frp-btn @click="withdrawPlan(actionsPayload)"
					 :loading="isWithdrawing"
					 v-if="isPlanWithdrawingAvailable"
					 outlined color="primary" elevation="0"
					 no-margin>
				{{ $t("buttons.withdrawVacationPlan") }}
			</frp-btn>
			<frp-btn @click="sendToReview"
					 v-if="isPlanSendingAvailable"
					 :loading="isSendingToReview"
					 :disabled="this.isStateContainsUnsavedChanges(VacationPlansSnapshotKeysEnum.NEW_VACATIONS)"
					 color="blue" dark elevation="0" no-margin>
				{{ $t("buttons.sendVacationPlanToReview") }}
			</frp-btn>
		</div>
	</v-card>
</template>

<script>
import FrpBtn from "@/components/buttons/FrpBtn.vue";
import HrVacationPlanStateLabel from "@/components/common/HrVacationPlanStateLabel.vue";
import { namespace } from "@/store/hr/modules/vacationPlans";
import { actionTypes, getterTypes, mutationTypes } from "@/store/hr/modules/vacationPlans/types";
import { HrVacationPlansPageModeEnum } from "@/store/hr/modules/vacationPlans/types/HrVacationPlansPageModeEnum";
import { VacationPlansSnapshotKeysEnum } from "@/store/hr/modules/vacationPlans/types/VacationPlansSnapshotKeysEnum";
import { HrVacationPlanStateEnum } from "@/types/hr/vacation/HrVacationPlanStateEnum";
import { Permissions } from "@/constants/permissions";
import HrVacationPlansApprovalStatusDialog from "@/views/hr/vacationPlans/dialogs/HrVacationPlansApprovalStatusDialog.vue";
import { cloneDeep, first, isArray } from "lodash";
import colorsMixin from "Mixins/colorsMixin";
import { createNamespacedHelpers } from "vuex";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);

export default {
	mixins: [colorsMixin],
	data() {
		return {
			namespace,
			Permissions,
			HrVacationPlansPageModeEnum,
			VacationPlansSnapshotKeysEnum,
			HrVacationPlanStateEnum
		};
	},
	props: {
		employee: {
			type: Object, // HrFormattedEmployeesItem
			required: true
		},
		totalVacationDays: {
			type: Number,
			required: true
		},
		plannedVacationDays: Number,
		vacationsPlans: {
			type: Array,
			default: () => []
		},
		vacationPlan: Object // HrVacationPlan | null
	},
	computed: {
		...mapState({
			mode: state => state.mode,
			editableVersionId: state => state.editableVersionId,
			loadingApprovalId: state => state.loadingApprovalId,
			approval: state => state.approval,
			isWithdrawing: state => state.isWithdrawing,
			isDraftDeleting: state => state.isDraftDeleting,
			isSendingToReview: state => state.isSendingToReview
		}),
		...mapGetters({
			isStateContainsUnsavedChanges: getterTypes.stateContainsUnsavedChanges,
			currentUserPermissions: getterTypes.currentUserPermissions,
			currentUser: getterTypes.currentUser
		}),
		isCanUpdateAllPlans() {
			return this.can(Permissions.HR_VACATIONS_PLAN_UPDATE);
		},
		isOwnPlan() {
			return this.employee.id === this.currentUser.id;
		},
		isPlanEditingAvailable() {
			if(this.isEditMode)
				return false;
			
			if(!this.isCanUpdateAllPlans && !this.isOwnPlan)
				return false;
			
			if(this.vacationPlan?.state === HrVacationPlanStateEnum.DRAFT)
				return true;
			
			const lastVacationPlan = first(this.vacationsPlans);
			
			// Редактировать можно только >последнюю< утверждённую версию
			if(this.vacationPlan?.state === HrVacationPlanStateEnum.APPROVED && lastVacationPlan.state !==
				HrVacationPlanStateEnum.IN_REVIEW)
				return this.vacationsPlans.find(x => x.state === HrVacationPlanStateEnum.APPROVED).id === this.vacationPlan.id;
		},
		isPlanCreationAvailable() {
			return (this.isCanUpdateAllPlans || this.isOwnPlan) && !this.isEditMode && !this.vacationPlan;
		},
		isPlanDeletingAvailable() {
			return (this.isCanUpdateAllPlans || this.isOwnPlan) && this.isEditMode &&
				this.vacationPlan?.state === HrVacationPlanStateEnum.DRAFT && this.editableVersionId && this.vacationsPlans.length > 1;
		},
		isPlanSendingAvailable() {
			return this.isOwnPlan && this.isEditMode &&
				this.vacationPlan?.state === HrVacationPlanStateEnum.DRAFT && this.editableVersionId;
		},
		isPlanWithdrawingAvailable() {
			return this.isOwnPlan && this.vacationPlan?.state === HrVacationPlanStateEnum.IN_REVIEW;
		},
		actionsPayload() {
			return {
				employeeId: this.employee.id,
				versionId: this.vacationPlan?.id
			};
		},
		isEditMode() {
			return this.mode === HrVacationPlansPageModeEnum.EDIT;
		}
	},
	methods: {
		...mapMutations({
			setIsVacationPlanApprovalDialogOpened: mutationTypes.SET_IS_VACATION_PLAN_APPROVAL_DIALOG_OPENED,
			setOpenedApprovalVacationPlanNumber: mutationTypes.SET_OPENED_APPROVAL_VACATION_PLAN_NUMBER
		}),
		...mapActions({
			editDraft: actionTypes.editDraft,
			createNewDraft: actionTypes.createNewDraft,
			createFirstDraft: actionTypes.createFirstDraft,
			deleteDraft: actionTypes.deleteDraft,
			sendToReview: actionTypes.sendToReview,
			withdrawPlan: actionTypes.withdrawPlan,
			fetchApproval: actionTypes.fetchApproval
		}),
		async handleEditPlan() {
			switch (this.vacationPlan.state) {
				case HrVacationPlanStateEnum.DRAFT:
					await this.editDraft(this.actionsPayload);
					break;
				case HrVacationPlanStateEnum.APPROVED:
					await this.createNewDraft(this.actionsPayload);
					break;
			}
		},
		async handleOpenVacationPlanApprovalDialog(approvalId) {
			if(!approvalId || this.loadingApprovalId === this.vacationPlan?.approvalId || this.vacationPlan?.state !== HrVacationPlanStateEnum.IN_REVIEW)
				return;
			
			await this.fetchApproval(approvalId);
			
			if(!this.approval)
				return;
			
			this.setOpenedApprovalVacationPlanNumber(cloneDeep(this.vacationsPlans).reverse().findIndex(x => x.approvalId ===
				this.approval.id) + 1);
			this.setIsVacationPlanApprovalDialogOpened(true);
		},
		can(value) {
			if(isArray(value))
				return value.some(x => this.currentUserPermissions.includes(x));
			else
				return this.currentUserPermissions.includes(value);
		}
	},
	components: {
		HrVacationPlansApprovalStatusDialog,
		HrVacationPlanStateLabel,
		FrpBtn
	}
};
</script>
