import RouteState from "@/store/shared/route/types/routeState";
import IPageState from "@/store/shared/base/types/pageState";
import VacationPlanCalendarFilter from "@/store/hr/modules/vacationPlanCalendar/types/vacationPlanCalendarFilter";
import { ApiHrVacationYear } from "@/api/hr/types/vacation/apiHrVacationYear";
import { CalendarDate } from "@/types/calendar/calendarDate";

export default class VacationPlanCalendarState implements IPageState {
	constructor(
		public filter: VacationPlanCalendarFilter,
		public route: RouteState,
		public isInitialized: boolean = false,
		public isDestroyed: boolean = false,
		public isYearsLoading: boolean = false,
		public isYearCreating: boolean = false,
		public isCalendarLoading: boolean = false,
		public isCalendarUpdating: boolean = false,
		public years: ApiHrVacationYear[] = [],
		public calendarDates: CalendarDate[] = [],
	)
	{
	}
}
