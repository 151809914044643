<template>
	<div>
		<div v-for="(item, i) in filteredFormattedItems" :key="i" class="mb-4">
			<hr-vacation-plans-plan-title v-bind="item"></hr-vacation-plans-plan-title>
			<v-card elevation="0" class="kpi-card">
				<template v-if="isEditMode">
					<hr-vacation-plans-progress-bar :max-days="item.totalVacationDays"
													:days="vacationsDaysCount"
													:has-long-vacation="hasLongVacation"
													class="pt-6 mb-4 mx-6">
					</hr-vacation-plans-progress-bar>
					
					<v-divider></v-divider>
				</template>
				
				<v-data-table :headers="headers"
							  :loading-text="$t('tables.loading')"
							  :loading="isItemsLoading"
							  hide-default-footer
							  item-key="startDate"
							  :items="isEditMode ? newVacations : item.vacationPlan?.plannedVacations"
							  :mobile-breakpoint="mobileBreakpoint"
							  :items-per-page="-1"
							  :options="options"
							  @click:row="handleItemSelect"
							  class="loan-table bod-table hr-table hr-vacations-table table-hidden-no-data d-flex flex-column clickable-rows">
					<template #item.startDate="{ item }">
						<span v-if="item.startDate">{{ formatNullableTableValue(format(item.startDate, dateFormat), "-") }}</span>
						<span v-else>-</span>
					</template>
					
					<template #item.endDate="{ item }">
						<span v-if="item.endDate">{{ formatNullableTableValue(format(item.endDate, dateFormat), "-") }}</span>
						<span v-else>-</span>
					</template>
					
					<template #item.days="{ item }">
						<span v-if="!isNil(item.days)">{{ $tc("pluralization.day", item.days) }}</span>
						<span v-else>-</span>
					</template>
					
					<template #item.applicationState="{ item }">
						<hr-vacation-application-state-label v-if="item.vacationApplication?.state"
															 :value="item.vacationApplication.state">
						</hr-vacation-application-state-label>
						<span v-else>-</span>
					</template>
				</v-data-table>
				
				<div v-if="isEditMode" class="d-flex mt-6 pb-5 px-2">
					<frp-btn @click="handleAddPeriod"
							 :disabled="isVacationsValid"
							 color="blue" dark elevation="0">
						{{ $t("buttons.addPeriod") }}
					</frp-btn>
					<v-spacer></v-spacer>
					<frp-btn @click="handleCancel"
							 outlined color="primary" elevation="0">
						{{ $t("buttons.cancel") }}
					</frp-btn>
					<frp-btn :disabled="!isVacationsValid || !isStateContainsUnsavedChanges(VacationPlansSnapshotKeysEnum.NEW_VACATIONS)"
							 :loading="isFormSaving"
							 v-if="item.employee.id === user.id"
							 @click="saveDraft"
							 color="blue" dark elevation="0">
						{{ $t("buttons.saveDraft") }}
					</frp-btn>
					<frp-btn :disabled="!isVacationsValid || isAllVacationsHaveApplications"
							 :loading="isSavingAndApproving"
							 v-if="can(Permissions.HR_VACATIONS_PLAN_UPDATE_APPROVE)"
							 @click="saveAndApproveDraft"
							 color="blue" dark elevation="0">
						{{ $t("buttons.saveAndApprove") }}
					</frp-btn>
				</div>
			</v-card>
		</div>
		
		<hr-update-vacation-dialog v-model="isUpdateVacationDialogOpened" v-if="isUpdateVacationDialogOpened"></hr-update-vacation-dialog>
	</div>
</template>

<script>
import FrpRouterLinkBtn from "@/components/buttons/FrpRouterLinkBtn.vue";
import FrpCalendar from "@/components/common/FrpCalendar.vue";
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import FrpIcon from "@/components/icon/FrpIcon.vue";
import HrVacationApplicationStateLabel from "@/components/labels/HrVacationApplicationStateLabel.vue";
import { listMixin } from "@/mixins/listMixin";
import { namespace as userNamespace } from "@/store/hr/modules/user";
import { checkIsVacationImmutable } from "@/store/hr/modules/vacationPlans/helpers/CheckIsVacationImmutable";
import { HrVacationPlansPageModeEnum } from "@/store/hr/modules/vacationPlans/types/HrVacationPlansPageModeEnum";
import { VacationPlansSnapshotKeysEnum } from "@/store/hr/modules/vacationPlans/types/VacationPlansSnapshotKeysEnum";
import { HrVacationPlanStateEnum } from "@/types/hr/vacation/HrVacationPlanStateEnum";
import { dateFormat } from "@/utils/formats";
import { formatNullableTableValue } from "@/utils/formatting";
import HrVacationPlansPlanTitle from "@/views/hr/vacationPlans/components/HrVacationPlansPlanTitle.vue";
import HrVacationPlansProgressBar from "@/views/hr/vacationPlans/components/HrVacationPlansProgressBar.vue";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import { Permissions } from "@/constants/permissions";
import { format } from "date-fns";
import { isArray, isEqual, isNil } from "lodash";
import { RouteNames } from "Router/hr/routes";
import { namespace } from "Store/hr/modules/vacationPlans";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlans/types";
import { createNamespacedHelpers } from "vuex";
import colorsMixin from "Mixins/colorsMixin";
import FrpBtn from "Components/buttons/FrpBtn";
import HrUpdateVacationDialog from "Views/hr/vacationPlans/dialogs/HrUpdateVacationDialog";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const { mapState: mapUserState } = createNamespacedHelpers(userNamespace);

export default {
	mixins: [listMixin, colorsMixin],
	data() {
		return {
			RouteNames,
			checkIsVacationImmutable,
			VacationPlansSnapshotKeysEnum,
			HrVacationPlansPageModeEnum,
			HrVacationPlanStateEnum,
			namespace,
			Permissions,
			format,
			dateFormat,
			isUpdateVacationDialogOpened: false,
			headers: [
				{
					text: this.$t("tables.start"),
					value: "startDate",
					sortable: false,
					width: "20%"
				},
				{
					text: this.$t("tables.end"),
					value: "endDate",
					sortable: false,
					width: "20%"
				},
				{
					text: this.$t("tables.daysCount"),
					value: "days",
					sortable: false,
					width: "20%"
				},
				{
					text: this.$t("tables.hrApplicationStatus"),
					value: "applicationState",
					sortable: false,
					width: "20%"
				}
			]
		};
	},
	computed: {
		...mapUserState({
			user: state => state.user
		}),
		...mapState({
			filter: state => state.filter,
			isFormSaving: state => state.form.isSaving,
			isSavingAndApproving: state => state.isSavingAndApproving,
			mode: state => state.mode,
			years: state => state.years,
			editableVacation: state => state.editableVacation,
			newVacations: state => state.newVacations,
			editableVersionId: state => state.editableVersionId
		}),
		...mapGetters({
			isStateContainsUnsavedChanges: getterTypes.stateContainsUnsavedChanges,
			currentUserPermissions: getterTypes.currentUserPermissions,
			formattedItems: getterTypes.formattedItems,
			filteredFormattedItems: getterTypes.filteredFormattedItems,
			hasLongVacation: getterTypes.hasLongVacation,
			isVacationDaysEnough: getterTypes.isVacationDaysEnough,
			vacationsDaysCount: getterTypes.vacationsDaysCount,
			editableVacationSiblingVacations: getterTypes.editableVacationSiblingVacations,
			editableVacationParentVacation: getterTypes.editableVacationParentVacation,
			isAllVacationsHaveApplications: getterTypes.isAllVacationsHaveApplications
		}),
		isVacationsValid() {
			return this.hasLongVacation && this.isVacationDaysEnough;
		},
		isEditMode() {
			return this.mode === HrVacationPlansPageModeEnum.EDIT;
		}
	},
	methods: {
		formatNullableTableValue,
		isNil,
		...mapMutations({
			setStateSnapshot: mutationTypes.SET_STATE_SNAPSHOT,
			addNewVacationsItem: mutationTypes.ADD_NEW_VACATIONS_ITEM,
			setEditableVacation: mutationTypes.SET_EDITABLE_VACATION,
			setEditableVacationPreviousVacationId: mutationTypes.SET_EDITABLE_VACATION_PREVIOUS_VACATION_ID,
			setEditableVacationIndex: mutationTypes.SET_EDITABLE_VACATION_INDEX,
			setIsNewVacationCreating: mutationTypes.SET_IS_NEW_VACATION_CREATING
		}),
		...mapActions({
			cancelChanges: actionTypes.cancelChanges,
			saveDraft: actionTypes.saveDraft,
			saveAndApproveDraft: actionTypes.saveAndApproveDraft,
			setReadMode: actionTypes.setReadMode
		}),
		handleItemSelect(vacation) {
			const { startDate, endDate, factDate } = vacation;
			
			if((checkIsVacationImmutable(vacation) || factDate) && this.mode === HrVacationPlansPageModeEnum.EDIT)
				return;
			
			this.handleOpenUpdateVacationDialog([startDate, endDate]);
		},
		handleAddPeriod() {
			this.setIsNewVacationCreating(true);
			
			const vacation = {
				previousVacationId: "",
				startDate: 0,
				endDate: 0,
				days: 0
			};
			
			this.addNewVacationsItem(vacation);
			
			this.handleOpenUpdateVacationDialog([0, 0], vacation);
		},
		handleOpenUpdateVacationDialog([start, end], item = null) {
			if(this.mode === HrVacationPlansPageModeEnum.READ)
				return;
			
			if(!item) {
				const idx = this.newVacations.findIndex(x => x.endDate === end && x.startDate === start);
				
				this.setEditableVacationIndex(idx);
				this.setEditableVacation(this.newVacations[idx]);
			} else {
				const idx = this.newVacations.findIndex(x => isEqual(x, item));
				
				this.setEditableVacationIndex(idx);
				this.setEditableVacation(item);
			}
			
			this.isUpdateVacationDialogOpened = true;
		},
		can(value) {
			if(isArray(value))
				return value.some(x => this.currentUserPermissions.includes(x));
			else
				return this.currentUserPermissions.includes(value);
		},
		setInternalFilterValues() {
		},
		async handleCancel() {
			await this.cancelChanges(VacationPlansSnapshotKeysEnum.NEW_VACATIONS);
			await this.setReadMode();
		}
	},
	components: {
		HrVacationApplicationStateLabel,
		FrpRouterLinkBtn,
		HrVacationPlansPlanTitle,
		HrVacationPlansProgressBar,
		FrpIcon,
		FrpCalendar,
		FrpDateField,
		FrpTextarea,
		FrpBottomSpace,
		FrpBtn,
		HrUpdateVacationDialog
	}
};
</script>
