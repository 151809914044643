import { ApiCalendarDayTypeEnum } from "@/api/calendar/types/ApiCalendarDayTypeEnum";
import { ApiCalendarDate } from "@/api/calendar/types/apiCalendarDate";
import { convertToTimestamp } from "@/utils/dates";
import { CalendarDateColorEnum, CalendarDateColorMap } from "@/types/calendar/CalendarDateColorEnum";

export interface CalendarDate {
	type: ApiCalendarDayTypeEnum;
	date: number;
	color?: CalendarDateColorEnum;
}

export class CalendarDateHelper {
	static map(source: ApiCalendarDate): CalendarDate {
		return {
			...source,
			date: convertToTimestamp(source.date) as number,
			color: CalendarDateColorMap.get(source.type)
		};
	}
}
