<template>
	<div class="frp-multiple-date-field" @keydown.delete="onDelete">
		<v-subheader v-if="label" class="pa-0 mb-1 grey--text text-caption" style="height: fit-content">
			<span>{{ label }}</span>
			<span v-if="required">*</span>
		</v-subheader>
		<v-text-field :rules="readonly ? [] : [...rules, ...defaultRules]"
					  readonly
					  :disabled="disabled"
					  :clearable="clearable"
					  ref="input"
					  :hide-details="hideDetails || (isPickerOpened && !errorState)"
					  :hint="hint"
					  autocomplete="off"
					  :placeholder="placeholder"
					  @change="onChange"
					  @click="onClick"
					  class="frp-date-field kpi-field" outlined dense
					  :class="{ 'frp-dense-field': dense }" :style="{ width }"
					  v-model="internalFormattedRowValue"
					  @update:error="updateErrorState">
			<template v-slot:append>
				<frp-icon src="ico_calendar" @click="onClick" :color="colors.grey.base"></frp-icon>
			</template>
		</v-text-field>
		
		<div v-if="isPickerOpened" class="d-flex">
			<v-date-picker v-for="i in 2"
						   :key="i"
						   multiple
						   class="ma-0 pa-0 hr-date-picker frp-calendar"
						   v-model="internalVuetifyFormattedRowDate"
						   full-width
						   @update:picker-date="onUpdatePickerDate(i, $event)"
						   first-day-of-week="1"
						   :show-current="false"
						   :max="vuetifyFormattedMaxRowDate"
						   :min="vuetifyFormattedMinRowDate"
						   :picker-date="format(addMonths(firstPickerDate, i - 1), isoYearMonthFormat)"
						   :header-date-format="getHeaderDateFormat"
						   :weekday-format="getWeekdayFormat"
						   no-title
						   show-adjacent-months>
			</v-date-picker>
		</div>
	</div>
</template>

<script>
import { ApiCalendarDayTypeEnum } from "@/api/calendar/types/ApiCalendarDayTypeEnum";
import { CalendarDateColorEnum } from "@/types/calendar/CalendarDateColorEnum";
import { formatDate, parseDate } from "@/utils/dates";
import FrpIcon from "Components/icon/FrpIcon";
import { ru } from "date-fns/locale";
import { capitalize, isEqual, sortBy } from "lodash";
import colorsMixin from "Mixins/colorsMixin";
import { isMyDateValidDateFormat, isoDateFormat, dateFormat, isoYearMonthFormat, friendlyMonthYearFormat, dayOfWeek } from "Utils/formats";
import { format, parse, subMonths, addMonths, startOfDay, isWeekend, isSameDay } from "date-fns";
import validator from "is-my-date-valid";
import {
	prepareMultipleDatesMaxDaysRule,
	prepareMultipleDatesMaxRule,
	prepareMultipleDatesMinRule,
	requiredRule
} from "Utils/validation";

const validate = validator({ format: isMyDateValidDateFormat });

export default {
	mixins: [colorsMixin],
	components: { FrpIcon },
	props: {
		disabled: Boolean,
		placeholder: {
			type: String,
			default: "Введите дату"
		},
		rules: {
			type: Array,
			default: () => []
		},
		calendarDates: {
			type: Array,
			default: () => []
		},
		label: String,
		hint: String,
		year: Number,
		readonly: Boolean,
		clearable: Boolean,
		required: Boolean,
		hideDetails: Boolean,
		dense: Boolean,
		maxDays: [Number, String],
		value: Array,
		width: {
			default: "100%"
		},
		type: {
			default: "number"
		}
	},
	model: {
		prop: "value",
		event: "update:value"
	},
	data() {
		return {
			isoYearMonthFormat,
			isPickerDateUpdating: false,
			isPickerOpened: false,
			errorState: false,
			internalFormattedRowValue: "",
			internalVuetifyFormattedRowDate: [],
			firstPickerDate: 0
		};
	},
	computed: {
		defaultRules() {
			const rules = [
				prepareMultipleDatesMaxRule({ getMax: () => this.max, format: dateFormat }),
				prepareMultipleDatesMinRule({ getMin: () => this.min, format: dateFormat })
			];
			if(this.required) rules.push(requiredRule());
			if(this.maxDays) rules.push(prepareMultipleDatesMaxDaysRule({ maxDaysCount: +this.maxDays, format: dateFormat }));
			return rules;
		},
		vuetifyFormattedMinRowDate() {
			return this.min && format(this.min, isoDateFormat);
		},
		vuetifyFormattedMaxRowDate() {
			return this.max && format(this.max, isoDateFormat);
		},
		min() {
			return startOfDay(new Date());
		},
		max() {
			return parseDate(`${this.year}-12-31`);
		},
		dialogSelectedInternalValue() {
			if(this.internalVuetifyFormattedRowDate) {
				return sortBy(this.internalVuetifyFormattedRowDate.map(d => parse(d, isoDateFormat, new Date())));
			} else {
				return [];
			}
		},
		currentValueFirstMonth() {
			const [startDate] = sortBy(this.value);
			
			if(!startDate)
				return parseDate(`${this.year}-01`, isoYearMonthFormat).getTime();
			
			return startDate;
		}
	},
	methods: {
		addMonths,
		format,
		getHeaderDateFormat(date) {
			return capitalize(format(parseDate(date, isoYearMonthFormat), friendlyMonthYearFormat, { locale: ru }));
		},
		getWeekdayFormat(date) {
			return format(parseDate(date), dayOfWeek, { locale: ru });
		},
		async onChange(value) {
			if(!value || value.split(", ").some(x => !validate(x))) {
				this.internalFormattedRowValue = "";
				this.internalVuetifyFormattedRowDate = [];
			}
		},
		async updateValue(value) {
			const isNewValue = !isEqual(sortBy(value.map(x => x.getTime())), sortBy(this.value));
			
			if(isNewValue) {
				if(this.type === "date")
					this.$emit("update:value", value);
				if(this.type === "number")
					this.$emit("update:value", value.map(x => x.getTime()));
				if(this.type === "string")
					this.$emit("update:value", value.map(x => formatDate(x, isoDateFormat)));
				
				// this.internalVuetifyFormattedRowDate = sortBy(value).map(d => format(d, isoDateFormat));
			}
		},
		async onUpdatePickerDate(idx, date) {
			if(this.isPickerDateUpdating)
				return;
			
			this.isPickerDateUpdating = true;
			
			if(idx === 1)
				this.firstPickerDate = subMonths(this.firstPickerDate, 1);
			else
				this.firstPickerDate = addMonths(this.firstPickerDate, 1);
			
			await this.$nextTick();
			
			this.isPickerDateUpdating = false;
		},
		closePicker(evt) {
			if(!evt.target.closest(".frp-multiple-date-field")) {
				this.isPickerOpened = false;
				document.removeEventListener("click", this.closePicker);
			}
		},
		onClick() {
			if(!this.$refs.input.isDisabled || this.isPickerOpened) {
				this.isPickerOpened = !this.isPickerOpened;
				// document.addEventListener("click", this.closePicker);
			}
		},
		updateErrorState(value) {
			this.errorState = value;
		},
		onDelete() {
			this.internalFormattedRowValue = "";
			// this.internalVuetifyFormattedRowDate = sortedIsoDates;
		},
		processDates() {
			const dateButtons = document.querySelectorAll(".v-date-picker-table button");
			
			dateButtons.forEach(el => {
				const monthName = el.closest(".v-picker__body").querySelector(".v-date-picker-header__value button").innerHTML
									.split(" ")[0];
				const day = el.querySelector(".v-btn__content").innerHTML;
				const date = parse(`${day} ${monthName}`, "d LLLL", new Date().setFullYear(this.year), { locale: ru });
				
				if(el.disabled)
					return;
				
				if(this.calendarDates?.length) {
					const isDateWeekend = isWeekend(date);
					const calendarDate = this.calendarDates.find(x => isSameDay(date, x.date));
					const color = calendarDate?.color || (isDateWeekend && CalendarDateColorEnum.RED);
					
					if(color)
						el.classList.add(`frp-calendar-date--${color}`);
					
					if(calendarDate?.type === ApiCalendarDayTypeEnum.Holiday)
						el.classList.add(`frp-calendar-date--disabled`);
				}
			});
		}
	},
	mounted() {
		if(!this.value.length) {
			if(new Date().getFullYear() === this.year)
				this.firstPickerDate = new Date();
		} else {
			this.firstPickerDate = this.currentValueFirstMonth;
		}
		
		this.onChange(this.internalFormattedRowValue);
	},
	watch: {
		async firstPickerDate(value) {
			if(value) {
				await this.$nextTick();
				this.processDates();
			}
		},
		async isPickerOpened(value) {
			if(value) {
				await this.$nextTick();
				this.processDates();
			}
		},
		disabled(value) {
			if(value)
				this.isPickerOpened = false;
		},
		internalFormattedRowValue(value) {
			if(!value || value.split(", ").some(x => !validate(x))) {
				this.updateValue(value ? sortBy(value.split(", ").map(d => parse(d, dateFormat, new Date()))) : []);
			}
			
			this.processDates();
		},
		async dialogSelectedInternalValue(value) {
			await this.$nextTick();
			this.internalFormattedRowValue = value.map(d => format(d, dateFormat)).join(", ");
			await this.updateValue(value);
		},
		value: {
			handler(newValue) {
				if(!newValue)
					return;
				
				const value = this.type === "string" ? newValue.map(x => parseDate(x)) : newValue;
				
				const sorted = sortBy(value);
				const sortedDates = sorted.map(d => format(d, dateFormat));
				const sortedIsoDates = sorted.map(d => format(d, isoDateFormat));
				
				this.internalFormattedRowValue = sortedDates.join(", ");
				this.internalVuetifyFormattedRowDate = sortedIsoDates;
			},
			immediate: true
		}
	}
};
</script>
