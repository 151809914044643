<template>
	<hr-content-layout container-class="pt-1 pt-lg-2 px-4 px-lg-4" bg-height="112">
		<v-card elevation="0" class="kpi-card px-6 py-6 mb-6">
			<div class="d-flex align-center mb-5" style="gap: 8px">
				<span style="font-size: 24px; font-weight: 600; line-height: 32px">{{ $t("titles.vacationPlans") }}</span>
				<v-spacer></v-spacer>
				
				<frp-btn :href="externalUrls.manuals.hrVacationPlans"
						 target="_blank"
						 color="primary"
						 elevation="0"
						 outlined>
					{{ $t("buttons.manual") }}
				</frp-btn>
				
				<frp-btn :to="{ name: RouteNames.VACATION_PLANS_ADMINISTRATION_EMPLOYEES, params: { year: filterValues.year } }"
						 v-if="isHrUserAdministrator"
						 color="blue" no-margin dark elevation="0">
					{{ $t("buttons.administration") }}
				</frp-btn>
				
				<frp-btn @click="exportPlans"
						 v-if="isHrUserAdministrator"
						 :disabled="!formattedItems.length || !formattedItems.every(x => x.vacationsPlans.some(x => x.state === HrVacationPlanStateEnum.APPROVED))"
						 :loading="isExporting"
						 color="blue" no-margin dark elevation="0">
					{{ $t("buttons.exportVacationPlans") }}
				</frp-btn>
			</div>
			<div class="d-flex flex-wrap" style="gap: 16px">
				<bod-autocomplete class="kpi-field"
								  hide-details
								  :loading="isYearsLoading"
								  style="width: 110px"
								  :items="yearValues"
								  required
								  v-model="internalFilterValues.year"
								  color="blue"
								  :placeholder="$t('fields.kpiYear.placeholder')">
				</bod-autocomplete>
				
				<bod-autocomplete item-text="name"
								  item-value="value"
								  class="kpi-field"
								  :readonly="isYearsLoading"
								  :disabled="!years.length || internalFilterValues.view !== HrVacationPlanViewEnum.GANTT || isFilterYearChanged || !filterValues.year"
								  @keydown.enter="applyFilter"
								  style="width: 115px"
								  :items="months"
								  v-model="internalFilterValues.month"
								  color="blue"
								  :placeholder="$t('fields.month.placeholder')"
								  hide-details>
				</bod-autocomplete>
				
				<frp-tree-autocomplete item-text="name"
									   item-value="id"
									   class="kpi-field"
									   :readonly="isYearsLoading"
									   :disabled="!years.length || isFilterYearChanged || !filterValues.year"
									   @keydown.enter="applyFilter"
									   multiple
									   :loading="isDepartmentsLoading"
									   style="width: 335px"
									   :items="departments"
									   required
									   v-model="internalFilterValues.departmentIds"
									   color="blue"
									   :placeholder="$t('fields.hrDepartment.placeholder')"
									   children-key="departments"
									   hide-details>
				</frp-tree-autocomplete>
				
				<bod-autocomplete item-text="fullName"
								  item-value="id"
								  class="kpi-field"
								  :readonly="isYearsLoading"
								  :disabled="!years.length || isFilterDepartmentsChanged || isFilterYearChanged || !filterValues.year"
								  @keydown.enter="applyFilter"
								  @firstclick="fetchEmployees"
								  multiple
								  :loading="isEmployeesLoading"
								  style="width: 288px"
								  :items="filterEmployees"
								  v-model="internalFilterValues.employeeIds"
								  color="blue"
								  :placeholder="$t('fields.hrFilterEmployee.placeholder')"
								  hide-details>
				</bod-autocomplete>
				
				<bod-autocomplete item-text="title"
								  item-value="id"
								  class="kpi-field"
								  :readonly="isYearsLoading"
								  :disabled="!years.length
								  	|| !currentUserVersions.length
								  	|| isFilterEmployeesChanged
								  	|| isFilterYearChanged
								  	|| !filterValues.year
								  	|| filterValues.view === HrVacationPlanViewEnum.GANTT"
								  :empty-if-disabled="filterValues.view === HrVacationPlanViewEnum.GANTT"
								  @keydown.enter="applyFilter"
								  style="width: 240px"
								  :items="currentUserVersions"
								  v-model="internalFilterValues.versionId"
								  color="blue"
								  :placeholder="$t('fields.vacationPlanVersion.placeholder')"
								  hide-details>
				</bod-autocomplete>
				
				<bod-autocomplete item-text="text"
								  item-value="value"
								  class="kpi-field"
								  :readonly="isYearsLoading"
								  :disabled="!years.length || isFilterYearChanged || !filterValues.year"
								  @keydown.enter="applyFilter"
								  style="width: 200px"
								  :items="views"
								  required
								  v-model="internalFilterValues.view"
								  color="blue"
								  :placeholder="$t('fields.vacationPlanView.placeholder')"
								  hide-details>
				</bod-autocomplete>
				
				<v-spacer></v-spacer>
				
				<frp-btn @click="resetFilter"
						 :disabled="isFilterEmpty || isCurrentUserPlansLoading"
						 color="primary"
						 outlined
						 elevation="0">
					{{ $t("buttons.reset") }}
				</frp-btn>
				
				<frp-btn @click="applyFilter"
						 :disabled="!isFilterChanged || !internalFilterValues.year || !internalFilterValues.departmentIds.length || !internalFilterValues.view"
						 no-margin
						 color="blue"
						 dark
						 elevation="0">
					{{ $t("buttons.apply") }}
				</frp-btn>
			</div>
		</v-card>
		
		<div v-if="!initialized || isItemsLoading || isLoading" class="d-flex justify-center align-center fill-height">
			<v-progress-circular size="50" indeterminate></v-progress-circular>
		</div>
		
		<template v-else-if="filterValues.year">
			<hr-vacation-plans-calendar v-if="filterValues.view === HrVacationPlanViewEnum.CALENDAR"></hr-vacation-plans-calendar>
			<hr-vacation-plans-list v-else-if="filterValues.view === HrVacationPlanViewEnum.LIST"></hr-vacation-plans-list>
			<hr-vacation-plans-gantt v-else-if="filterValues.view === HrVacationPlanViewEnum.GANTT"></hr-vacation-plans-gantt>
		</template>
		
		<hr-vacation-plans-approval-status-dialog v-if="isApprovalDialogOpened" v-model="isApprovalDialogOpened" />
		
		<frp-bottom-space height="24"></frp-bottom-space>
	</hr-content-layout>
</template>

<script>
import FrpDateField from "@/components/fields/FrpDateField.vue";
import FrpTextarea from "@/components/fields/FrpTextarea.vue";
import { externalUrls } from "@/constants/hr/externalUrls";
import { getVacationPlansDefaultFilter } from "@/store/hr/modules/vacationPlans/helpers/getVacationPlansDefaultFilter";
import storeManager from "@/store/manager";
import { HrVacationPlanStateEnum } from "@/types/hr/vacation/HrVacationPlanStateEnum";
import { HrVacationPlanViewEnum } from "@/types/hr/vacation/HrVacationPlanViewEnum";
import { findParentIds, flatSubsWithLevels } from "@/utils/array";
import { getMonthName } from "@/utils/dates";
import HrVacationPlansApprovalStatusDialog from "@/views/hr/vacationPlans/dialogs/HrVacationPlansApprovalStatusDialog.vue";
import HrVacationPlansGantt from "@/views/hr/vacationPlans/views/HrVacationPlansGantt.vue";
import HrVacationPlansList from "@/views/hr/vacationPlans/views/HrVacationPlansList.vue";
import FrpLinkBtn from "Components/buttons/FrpLinkBtn";
import FrpRouterLinkBtn from "Components/buttons/FrpRouterLinkBtn";
import FrpPagination from "Components/common/FrpPagination";
import BodAutocomplete from "Components/fields/BodAutocomplete";
import FrpTreeAutocomplete from "Components/fields/FrpTreeAutocomplete";
import FrpIcon from "Components/icon/FrpIcon";
import BodContentLayout from "Components/layouts/BodContentLayout";
import FrpBottomSpace from "Components/layouts/FrpBottomSpace";
import HrContentLayout from "Components/layouts/HrContentLayout";
import { assign, isEqual, capitalize, isArray } from "lodash";
import { RouteNames } from "Router/hr/routes";
import { Permissions } from "@/constants/permissions";
import { namespace } from "Store/hr/modules/vacationPlans";
import { actionTypes, getterTypes, mutationTypes } from "Store/hr/modules/vacationPlans/types";
import { getterTypes as userGetterTypes } from "Store/hr/modules/user/types";
import { createNamespacedHelpers } from "vuex";
import { listMixin } from "Mixins/listMixin";
import colorsMixin from "Mixins/colorsMixin";
import storeModuleBasedPage from "Mixins/storeModuleBasedPage";
import FrpAlert from "Components/alerts/FrpAlert";
import FrpAlerts from "Components/alerts/FrpAlerts";
import FrpBtn from "Components/buttons/FrpBtn";
import FrpCheckbox from "Components/fields/FrpCheckbox";
import FrpTextField from "Components/fields/FrpTextField";
import BodFooter from "Components/layouts/BodFooter";
import BodMain from "Components/layouts/BodMain";
import HrVacationPlansCalendar from "Views/hr/vacationPlans/views/HrVacationPlansCalendar";

const { mapState, mapActions, mapGetters, mapMutations } = createNamespacedHelpers(namespace);
const hrUserModuleHelpers = createNamespacedHelpers(storeManager.hr.user.namespace);

export default {
	mixins: [listMixin, colorsMixin, storeModuleBasedPage],
	metaInfo() {
		return {
			title: this.$t("titles.vacationPlans")
		};
	},
	data() {
		return {
			RouteNames,
			namespace,
			Permissions,
			HrVacationPlanViewEnum,
			HrVacationPlanStateEnum,
			internalFilterValues: {
				year: undefined,
				month: null,
				departmentIds: [],
				employeeIds: [],
				versionId: "",
				view: HrVacationPlanViewEnum.UNKNOWN,
				isAllEmployees: false
			}
		};
	},
	computed: {
		externalUrls() {
			return externalUrls;
		},
		...mapState({
			initialized: state => state.isInitialized,
			isLoading: state => state.isLoading,
			isCurrentUserPlansLoading: state => state.isCurrentUserPlansLoading,
			isVacationPlanApprovalDialogOpened: state => state.isVacationPlanApprovalDialogOpened,
			isYearsLoading: state => state.isYearsLoading,
			isDepartmentsLoading: state => state.isDepartmentsLoading,
			isEmployeesLoading: state => state.isEmployeesLoading,
			currentUserPlans: state => state.currentUserPlans,
			years: state => state.years,
			departments: state => state.departments,
			employees: state => state.employees,
			isExporting: state => state.isExporting
		}),
		...mapGetters({
			formattedItems: getterTypes.formattedItems,
			filteredFormattedItems: getterTypes.filteredFormattedItems,
			isNoData: getterTypes.isListingEmpty,
			yearValues: getterTypes.yearValues,
			currentUserVersions: getterTypes.currentUserVersions,
			currentUserPermissions: getterTypes.currentUserPermissions,
			currentUser: getterTypes.currentUser
		}),
		...hrUserModuleHelpers.mapGetters({
			isHrUserAdministrator: userGetterTypes.isHrUserAdministrator
		}),
		filter() {
			return {
				year: this.internalFilterValues.year,
				month: this.internalFilterValues.month,
				departmentIds: this.internalFilterValues.departmentIds,
				employeeIds: this.internalFilterValues.employeeIds,
				versionId: this.internalFilterValues.versionId,
				view: this.internalFilterValues.view
			};
		},
		isFilterChanged() {
			return !isEqual(Object.fromEntries(Object.entries(this.internalFilterValues)), assign({}, this.filterValues));
		},
		isFilterEmpty() {
			const item = this.listing.items.find(x => x.employee.id === this.currentUser.id);
			return isEqual(getVacationPlansDefaultFilter(this.years, item, this.currentUser), this.filterValues);
		},
		months() {
			if(this.internalFilterValues.view !== HrVacationPlanViewEnum.GANTT) return [];
			
			return [...Array(12).keys()].map((i) => ({ name: capitalize(getMonthName(i)), value: ++i }));
		},
		views() {
			const filtered = Object.values(HrVacationPlanViewEnum).filter(x => {
				switch (x) {
					case HrVacationPlanViewEnum.UNKNOWN:
						return false;
					case HrVacationPlanViewEnum.GANTT:
						return this.can(Permissions.HR_VACATIONS_GANTT_READ);
					default:
						return true;
				}
			});
			
			return filtered.map(x => ({ text: this.$t(`aliases.vacationPlanView.${x}`), value: x }));
		},
		filterEmployees() {
			if(!this.employees.length || !this.departments.length)
				return [];
			
			const flattedDepartments = flatSubsWithLevels("departments", this.departments);
			
			return this.employees.filter(x => {
				if(![...findParentIds(flattedDepartments, x.department),
					x.department.id].some(y => this.filterValues.departmentIds.includes(y)))
					return false;
				
				return this.formattedItems.some(x => x.employee.id === x.employee.id);
			});
		},
		isFilterYearChanged() {
			return this.internalFilterValues.year !== this.filterValues.year;
		},
		isFilterEmployeesChanged() {
			return !isEqual(this.internalFilterValues.employeeIds, this.filterValues.employeeIds);
		},
		isFilterDepartmentsChanged() {
			return !isEqual(this.internalFilterValues.departmentIds, this.filterValues.departmentIds);
		},
		isApprovalDialogOpened: {
			get() {
				return this.isVacationPlanApprovalDialogOpened;
			},
			set(value) {
				this.setIsVacationPlanApprovalDialogOpened(value);
			}
		}
	},
	methods: {
		...mapMutations({
			setFilterYear: mutationTypes.SET_FILTER_YEAR,
			setFilterMonth: mutationTypes.SET_FILTER_MONTH,
			setFilterDepartmentIds: mutationTypes.SET_FILTER_DEPARTMENT_IDS,
			setFilterEmployeeIds: mutationTypes.SET_FILTER_EMPLOYEE_IDS,
			setFilterVersionId: mutationTypes.SET_FILTER_VERSION_ID,
			setFilterView: mutationTypes.SET_FILTER_VIEW,
			setFilterIsAllEmployees: mutationTypes.SET_FILTER_IS_ALL_EMPLOYEES,
			setFilter: mutationTypes.SET_FILTER,
			setStateSnapshot: mutationTypes.SET_STATE_SNAPSHOT,
			setIsVacationPlanApprovalDialogOpened: mutationTypes.SET_IS_VACATION_PLAN_APPROVAL_DIALOG_OPENED
		}),
		...mapActions({
			setReadMode: actionTypes.setReadMode,
			fetchEmployees: actionTypes.fetchEmployees,
			updateDefaultRoute: actionTypes.updateDefaultRoute,
			exportPlans: actionTypes.exportPlans
		}),
		setInternalFilterValues() {
			Object.keys(this.internalFilterValues).forEach(key => this.internalFilterValues[key] =
				Array.isArray(this.filterValues[key]) ? [...this.filterValues[key]] : this.filterValues[key]);
		},
		async applyFilter() {
			await this.setReadMode();
			
			if(this.internalFilterValues.year !== this.filterValues.year) {
				this.resetFilter();
				this.setFilterYear(this.internalFilterValues.year);
			} else {
				this.setFilterMonth(this.internalFilterValues.month);
				this.setFilterVersionId(this.internalFilterValues.versionId);
				this.setFilterView(this.internalFilterValues.view);
				
				if(!isEqual(this.internalFilterValues.departmentIds, this.filterValues.departmentIds)) {
					this.setFilterDepartmentIds(this.internalFilterValues.departmentIds || []);
					this.setFilterEmployeeIds([]);
					this.setFilterVersionId(null);
				} else if(!isEqual(this.internalFilterValues.employeeIds, this.filterValues.employeeIds)) {
					this.setFilterEmployeeIds(this.internalFilterValues.employeeIds);
					
					if(this.internalFilterValues.employeeIds.length === 1) {
						const item = this.filteredFormattedItems.find(x => x.employee.id === this.internalFilterValues.employeeIds[0])
						this.setFilterVersionId(item.vacationPlan?.id || null);
					} else {
						this.setFilterVersionId(null);						
					}
				}
			}
		},
		resetFilter() {
			const item = this.listing.items.find(x => x.employee.id === this.currentUser.id);
			const defaults = getVacationPlansDefaultFilter(this.years, item, this.currentUser);
			this.setFilter(defaults);
		},
		can(value) {
			if(isArray(value))
				return value.some(x => this.currentUserPermissions.includes(x));
			else
				return this.currentUserPermissions.includes(value);
		}
	},
	created() {
		this.initializeStore();

		window.addEventListener("popstate", this.setReadMode);
	},
	beforeDestroy() {
		window.removeEventListener("popstate", this.setReadMode);
	},
	watch: {
		"filterValues.year"(value, prev) {
			if(prev)
				this.setFilterVersionId(null);
		},
		"filterValues.view"(value) {
			if(value !== HrVacationPlanViewEnum.GANTT)
				this.setFilterMonth(null);
			else if(this.initialized) {
				this.setFilterIsAllEmployees(true);
				this.setFilterVersionId(null);
				this.setFilterDepartmentIds([this.currentUser.department.id]);
				this.setFilterEmployeeIds([]);
			}
		},
		"filterValues.employeeIds"(value, prev) {
			if(!value.length && !!prev.length)
				this.setFilterIsAllEmployees(true);
			else if(!!value.length && !prev.length)
				this.setFilterIsAllEmployees(false);
			
			if(value.length !== 1)
				this.setFilterVersionId(null);
		}
	},
	components: {
		HrVacationPlansApprovalStatusDialog,
		HrVacationPlansGantt,
		HrVacationPlansList,
		FrpDateField,
		FrpTextarea,
		FrpTreeAutocomplete,
		FrpRouterLinkBtn,
		FrpLinkBtn,
		FrpBottomSpace,
		BodAutocomplete,
		FrpPagination,
		FrpIcon,
		FrpBtn,
		FrpCheckbox,
		FrpTextField,
		BodMain,
		FrpAlerts,
		FrpAlert,
		BodFooter,
		BodContentLayout,
		HrContentLayout,
		HrVacationPlansCalendar
	}
};
</script>
